import React from "react";
import ourMission from "assets/new-images/our_mission.png";
import InnovationVector from "assets/new-images/innovation_vector.png";
import FounderImage from "assets/new-images/founder.png";
import MissionIdea from "assets/new-images/mission_idea.png";
import MeetPeopleVector from "assets/new-images/meet_people_vector.png";
import AboutBanner from "assets/new-images/contact_bg.png";
import AboutBannerImage from "assets/new-images/about.png";
import Newsletter from "components/Newsletter";
// Icons
import { FaRegCircleCheck, FaArrowRightLong } from "react-icons/fa6";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { GrSend } from "react-icons/gr";
import { SlSettings } from "react-icons/sl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const About = () => {
  const bannerPagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="!w-[15px] !h-[15px] rounded-full bg-white !opacity-100 ${className}"></span>`;
    },
    bulletActiveClass: "!bg-blue-light",
  };

  return (
    <>
      <section>
        <Swiper
          slidesPerView={1}
          pagination={bannerPagination}
          loop={true}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <div className="relative py-2 bg-blue-dark overflow-hidden min-h-[350px] flex flex-col items-center justify-center">
              <img
                src={AboutBanner}
                alt={""}
                className="object-cover w-full h-full absolute inset-0"
              />
              <div className="sm:w-[90%] ml-auto px-4 relative">
                <div className="md:flex items-center">
                  <div className="md:w-4/6 md:pr-3 text-white sm:flex">
                    <div className="flex-grow">
                      <h2
                        className="text-2xl md:text-3xl font-semibold leading-10"
                        data-aos="fade-up"
                      >
                        High quality IT-Solutions for help your problems about
                        technology.
                      </h2>
                      <p
                        className="md:text-lg mt-5"
                        data-aos="fade-up"
                      >
                        Quis eleifend quam adipiscing vitae proin sagittis nisl.
                        Lectus vestibulum mattis.
                      </p>
                    </div>
                  </div>
                  <div className="md:w-4/12 pt-7 md:pt-0 md:pl-3 hidden md:block">
                    <img
                      src={AboutBannerImage}
                      alt=""
                      className="ml-auto mr-[-16px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      {/* Who we are */}
      <section className="py-10 bg-grey-2">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-center">
            <h3 className="text-2xl md:text-3xl mb-6 text-secondary">
              Who we are?
            </h3>
            <h2 className="text-4xl md:text-5xl font-semibold mb-10 text-primary">
              Builders of a better working world
            </h2>
            <p
              className="text-lg md:text-xl"
              data-aos="fade-up"
            >
              Your trusted IT solutions provider, delivering value and results
              for businesses. Harness the power of our expertise and advanced
              technology to drive your business forward. Partner with us for
              innovative IT solutions that fuel growth and success.
            </p>
          </div>
        </div>
      </section>
      {/* Ideas to life */}
      <section className="py-14 overflow-hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="lg:flex items-center">
            <div className="lg:w-1/2 lg:pr-4">
              <img
                src={MissionIdea}
                alt=""
                data-aos="fade-right"
              />
              <ul className="bg-blue-dark rounded-2xl p-5 sm:p-10 mt-10 flex flex-wrap">
                <li
                  className="px-2 text-center my-4 w-1/2 2xl:w-1/4"
                  data-aos="fade-up"
                >
                  <span className="block text-secondary text-2xl md:text-3xl text-center">
                    99%
                  </span>
                  <span className="block text-white md:text-lg">
                    Client Retention
                  </span>
                </li>
                <li
                  className="px-2 text-center my-4 w-1/2 2xl:w-1/4"
                  data-aos="fade-up"
                >
                  <span className="block text-secondary text-2xl md:text-3xl text-center">
                    2
                  </span>
                  <span className="block text-white md:text-lg">
                    Years of Service
                  </span>
                </li>
                <li
                  className="px-2 text-center my-4 w-1/2 2xl:w-1/4"
                  data-aos="fade-up"
                >
                  <span className="block text-secondary text-2xl md:text-3xl text-center">
                    10+
                  </span>
                  <span className="block text-white md:text-lg">
                    Team of Professionals
                  </span>
                </li>
                <li
                  className="px-2 text-center my-4 w-1/2 2xl:w-1/4"
                  data-aos="fade-up"
                >
                  <span className="block text-secondary text-2xl md:text-3xl text-center">
                    10+
                  </span>
                  <span className="block text-white md:text-lg">
                    Satisfied Clients
                  </span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2 pt-7 lg:pt-0 lg:pl-4">
              <h2
                className="text-3xl md:text-4xl font-semibold text-primary"
                data-aos="fade-up"
              >
                We're on a mission to bring ideas to life.
              </h2>
              <p
                className="md:text-lg my-10 text-gray-5"
                data-aos="fade-up"
              >
                Building an enterprise level site doesn't need nightmare or cost
                your thousands. Felix is purpose built for ease of use and
                completxability to create even the most powerful of products.
              </p>
              <ul className="border-t border-grey-9">
                <li
                  className="flex md:text-lg mt-7 black-1"
                  data-aos="fade-up"
                >
                  <div className="bg-grey-7 flex items-center justify-center mr-5 text-blue-2 w-[110px] rounded-2xl">
                    <GrSend className="w-[35px] h-[35px]" />
                  </div>
                  <div className="">
                    <h4 className="text-xl md:text-2xl text-black-1">
                      How technology started
                    </h4>
                    <p className="md:text:lg text-gray-5">
                      Building an enterprisedoesn't need your thousands.
                    </p>
                  </div>
                </li>
                <li
                  className="flex md:text-lg mt-7 black-1"
                  data-aos="fade-up"
                >
                  <div className="bg-grey-7 flex items-center justify-center mr-5 text-blue-2 w-[110px] rounded-2xl">
                    <SlSettings className="w-[35px] h-[35px]" />
                  </div>
                  <div className="">
                    <h4 className="text-xl md:text-2xl text-black-1">
                      Endless Possibilities
                    </h4>
                    <p className="md:text:lg text-gray-5">
                      Building an enterprisedoesn't need your thousands.
                    </p>
                  </div>
                </li>
              </ul>
              <div className="mt-5 table ml-auto">
                <button
                  className=" flex items-center text-blue-dark hover:text-secondary"
                  data-aos="fade-left"
                >
                  See more Informations <FaArrowRightLong className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Mission */}
      <section className="py-14 overflow-hidden ">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <div
                className="w-[69px] h-[5px] bg-blue-light"
                data-aos="fade-right"
              ></div>
              <h2
                className="text-4xl md:text-5xl font-semibold mt-5 text-primary"
                data-aos="fade-up"
              >
                Our Mission
              </h2>
              <p
                className="md:text-lg mt-5 text-gray-5"
                data-aos="fade-up"
              >
                Building an enterprise level site doesn't need nightmare or cost
                your thousands. Felix is purpose built for ease of use and
                completxability to create.
              </p>
              <ul>
                <li
                  className="flex items-center md:text-lg mt-5 black-1"
                  data-aos="fade-right"
                >
                  <FaRegCircleCheck className="mr-2 text-green" /> Posting to
                  social media, blogs, and messengers
                </li>
                <li
                  className="flex items-center md:text-lg mt-5 black-1"
                  data-aos="fade-right"
                >
                  <FaRegCircleCheck className="mr-2 text-green" /> Working with
                  images and videos
                </li>
                <li
                  className="flex items-center md:text-lg mt-5 black-1"
                  data-aos="fade-right"
                >
                  <FaRegCircleCheck className="mr-2 text-green" /> The Future of
                  Writing Blog Articles
                </li>
              </ul>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={ourMission}
                alt=""
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Our Values */}
      <section className="py-14">
        <div className="sm:w-[80%] mx-auto px-4">
          <div
            className="flex items-center flex-col mb-14"
            data-aos="fade-up"
          >
            <h2 className="text-4xl md:text-5xl font-semibold text-primary">
              Our Values
            </h2>
            <div className="w-[135px] h-[5px] bg-blue-light mt-5"></div>
          </div>
          <div className="flex flex-wrap">
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Innovation
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Growth
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Ownership
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Team Work
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Commitment
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
            <div
              className="group flex flex-col items-center text-center w-full sm:w-1/2 lg:w-1/3 p-5 mb-10 rounded-md hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={InnovationVector}
                alt=""
              />
              <h3 className="mt-3 text-xl md:text-2xl font-semibold text-black-1 group-hover:text-secondary">
                Positivity
              </h3>
              <p className="md:text-lg text-gray-5 mt-4">
                Building an enterprisedoesn't need nightmare or cost your
                thousands Felix is purpose built.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Co founder */}
      <section className="relative overflow-hidden">
        <img
          src={MeetPeopleVector}
          alt={""}
          className="absolute top-[-40px] right-[10%]"
        />
        <img
          src={MeetPeopleVector}
          alt={""}
          className="absolute bottom-[-50px] left-[10%]"
        />
        <div className="py-14 bg-blue-dark mb-[100px] relative">
          <div className="sm:w-[80%] mx-auto px-4">
            <div className="md:flex items-center">
              <div className="">
                <div
                  className="relative text-xl md:text-3xl"
                  data-aos="fade-up"
                >
                  <RiDoubleQuotesL className="text-blue-1 absolute top-0 left-0" />
                  <p className="text-white text-center px-10">
                    Talents was fantastic and worked well with our scrum teams
                    and helped us to deliver consistent results.
                  </p>
                  <RiDoubleQuotesR className="text-blue-1 absolute bottom-0 right-[20px]" />
                </div>
                <h3
                  className="text-white text-center text-lg md:text-xl mt-6"
                  data-aos="fade-up"
                >
                  Vivek Mishra, <br />
                  Co-Founder and CEO, Vivcloud Tech.
                </h3>
              </div>
              <div className="md:w-1/2 pt-7 md:pt-0 md:pl-40 relative hidden">
                <div className="absolute top-[20px] md:top-[-25px] right-0 sm:right-[5px] border-8 border-secondary w-[65%] h-[calc(100%-200px)] md:h-[calc(100%-0px)]"></div>
                <img
                  src={FounderImage}
                  alt=""
                  className="mt-[50px] md:mt-0 pr-4 sm:pr-0 relative"
                  data-aos="fade-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default About;
