import serviceBg from "assets/new-images/services_bg.png";
import BannerImage from "assets/new-images/content_creation.png";
import SuccessStoryImage1 from "assets/new-images/success_story1.png";
import SuccessStoryImage2 from "assets/new-images/success_story2.png";
import Newsletter from "components/Newsletter";

const ContentCreator = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <h1
                className="text-4xl md:text-6xl font-semibold text-white text-center"
                data-aos="fade-up"
              >
                Content Creator
              </h1>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={BannerImage}
                alt=""
                className="mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Service Details */}
      <section className="py-20">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Our Expertise
            </h2>
            <p data-aos="fade-up">
              In the vast expanse of the digital landscape, content is the pulse
              that keeps the online world vibrant and alive. As dedicated
              content creators, we craft compelling narratives that resonate
              with audiences, engage customers, and build brand stories that not
              only inform but inspire.
            </p>
            <ul className="list-disc ml-10">
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Mastering the Art of Engagement:
                </strong>
                Our content creators are maestros of engagement, weaving words,
                images, and multimedia into a tapestry of content that speaks
                directly to the heart of your audience. We understand that
                engagement is not just about grabbing attention—it's about
                holding it, nurturing it, and converting it into lasting
                relationships.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  A Palette of Content Strategies:
                </strong>
                Whether it's through punchy blog posts, insightful articles,
                vibrant videos, or captivating infographics, our content spans a
                spectrum of formats. Each piece is a strategic blend of
                creativity and marketing acumen, designed to align with your
                business goals while elevating the user experience.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  SEO-Driven Content for Visibility:
                </strong>
                Visibility is key in the digital space. Our content is not just
                beautiful—it's smart. SEO-driven and keyword-optimized, we
                ensure that your message is not only heard but also seen,
                appearing where it matters most: in front of your audience.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">Adaptable Tone and Style:</strong>
                Every brand is unique, and so is its voice. Our content creation
                is adaptable, reflecting your brand's tone, style, and
                personality. From professional to playful, from informative to
                inspirational, we tailor our words to fit your brand's identity
                and your audience's expectations.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">Your Vision, Our Words:</strong>
                Let's tell your story together. Our content creators are ready
                to translate your vision into content that not only speaks
                volumes but acts as a catalyst for growth and engagement.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Success Story */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Success Story
            </h2>
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage1}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How AI breakthrough to the Future?
                  </h3>
                </div>
              </div>
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage2}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How Technology emerging in 2023?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default ContentCreator;
