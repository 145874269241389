import React from "react";
import { Helmet } from "react-helmet-async";

const GoogleAnalyticsScript = () => {
  const googleAnalyticsId = "G-JM1J0K408S";
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalyticsScript;
