import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JobApplyImg from "assets/new-images/job-apply.webp";
import { FaAngleRight } from "react-icons/fa6";
import Newsletter from "components/Newsletter";
import { validateOnPaste, validateOnNumber } from "utils";
import { getJobByIdApi, applyJobsApi } from "service";

const budgetRanges = [
  {
    name: "Below 5k",
    value: "Below_5k",
  },
  {
    name: "5k - 10k",
    value: "5k-10k",
  },
  {
    name: "10-k - 20k",
    value: "10k-20k",
  },
  {
    name: "20k - 30k",
    value: "20k-30k",
  },
  {
    name: "30k - 40k",
    value: "30k-40k",
  },
  {
    name: "40k - 50k",
    value: "40k-50k",
  },
  {
    name: "Above 50k",
    value: "Above_50k",
  },
];

const JobApplication = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();

  const MySwal = withReactContent(Swal);
  const initialValues = {
    resume: "",
    name: "",
    email: "",
    phone: "",
    currentEmployer: "",
    currentDesignation: "",
    currentSalary: "",
    expectedSalary: "",
    noticePeriod: "",
    currentLocation: "",
    linkedInUrl: "",
    gitHubUrl: "",
    isAgreeTerms: false,
    isAgreePrivacy: false,
    isImmediateJoining: false,
    isReadyToRelocate: false,
  };

  const validationSchema = yup.object().shape({
    resumeFile: yup.string().required("Resume is required"),
    name: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .required("Name is required"),
    email: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .email("Invalid email address")
      .required("Email is required"),
    phone: yup
      .string()
      .required("Phone is required")
      .min(10, "Phone number must be at least 10 characters long")
      .max(10, "Phone number must be no longer than 15 characters"),
    currentEmployer: yup
      .string()
      .matches(/^\S/, "Job Title cannot start with a space")
      .required("Job Title is required"),
    currentDesignation: yup
      .string()
      .matches(/^\S/, "Designation cannot start with a space")
      .required("Designation is required"),
    currentSalary: yup
      .string()
      .matches(/^\S/, "Current Salary cannot start with a space")
      .required("Current Salary is required"),
    expectedSalary: yup
      .string()
      .matches(/^\S/, "Expected Salary cannot start with a space")
      .required("Expected Salary is required"),
    noticePeriod: yup
      .string()
      .matches(/^\S/, "Notice Period cannot start with a space")
      .required("Notice Period is required"),
    currentLocation: yup
      .string()
      .matches(/^\S/, "Current Location cannot start with a space")
      .required("Current Location is required"),
    isAgreeTerms: yup
      .boolean()
      .required("Agree to terms is required")
      .oneOf([true], "You must agree to the terms."),
    isAgreePrivacy: yup
      .boolean()
      .required("Agree to privacy policy is required")
      .oneOf([true], "You must agree to the privacy policy."),
  });

  const [jobInfo, setJobInfo] = useState({
    loading: true,
    data: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const loadJobById = useCallback(async () => {
    try {
      const resp = await getJobByIdApi(jobId);
      setJobInfo({ loading: false, data: resp.data || {} });
    } catch {
      setJobInfo({ loading: false, data: {} });
    }
  }, [jobId]);

  useEffect(() => {
    loadJobById();
  }, [loadJobById]);

  const handleSubmit = async (value, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    setSubmitting(true);
    const payload = { ...value };
    delete payload["resumeFile"];
    try {
      const resp = await applyJobsApi({ carrierId: jobId, ...payload });
      console.log(resp);
      setSubmitting(false);
      resetForm();
      MySwal.fire({
        title: resp.data?.message || "Thank You! We will contact you soon!",
        icon: "success",
        showCloseButton: true,
        showConfirmButton: false,
        timer: 5000,
      });
      setIsLoading(false);
    } catch (error) {
      const { response } = error;
      setSubmitting(false);
      toast.error(response.data?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const fileToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (event) => {
          const arrayBuffer = event.target.result;
          const byteArray = new Uint8Array(arrayBuffer);
          const fileByteArray = Array.from(byteArray);
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <>
      <section className="py-20 relative overflow-hidden min-h-[400px] flex items-center justify-center">
        <img
          src={JobApplyImg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full"
        />
        <div className="w-[80%] mx-auto px-4 relative">
          <div className="text-white text-center">
            <h1 className="text-4xl md:text-5xl font-semibold">
              {jobInfo.data?.jobTitle}
            </h1>
          </div>
        </div>
      </section>
      <section className="py-20 relative overflow-hidden">
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <ul className="hidden md:flex items-center text-grey-6">
            <li
              onClick={() => navigate("/career/jobs")}
              className="flex items-center mr-2 text-lg md:text-xl cursor-pointer"
            >
              Jobs <FaAngleRight className="ml-2 text-black-default" />
            </li>
            <li
              onClick={() => navigate(`/career/jobs/${jobId}`)}
              className="flex items-center mr-2 text-lg md:text-xl cursor-pointer"
            >
              Job Details <FaAngleRight className="ml-2 text-black-default" />
            </li>
            <li className="text-lg md:text-xl">
              Job Application
            </li>
          </ul>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="mt-2">
                    <div className="relative bg-blue-6 min-h-[150px] flex flex-col items-center justify-center rounded-md border hover:border hover:border-dashed hover:border-primary">
                      <input
                        type="file"
                        id="fileupload"
                        className="absolute inset-0 cursor-pointer opacity-0"
                        accept="application/pdf"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (!file) return;
                          const allowed_types = ["application/pdf"];
                          if (!allowed_types.includes(file.type)) {
                            toast.error("Only PDF files are allowed", "Error");
                            e.target.value = null;
                            return;
                          }
                          const maxFileSize = 5 * 1024 * 1024;
                          if (file.size > maxFileSize) {
                            toast.error(
                              "File size must be less than 5 MB",
                              "Error"
                            );
                            e.target.value = null;
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = async (event) => {
                            const byteArray = await fileToByteArray(file);
                            setFieldValue("resume", byteArray);
                            setFieldValue("resumeFile", file);
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                      <span className="text-center text-lg md:text-xl text-blue-dark">
                        Upload Your Resume{" "}
                      </span>
                      <span className="file-link block text-grey-6">
                        Only Pdf
                      </span>
                      <span className="text-gray-700 mt:1">
                        {values.resumeFile?.name}
                      </span>
                    </div>
                    <ErrorMessage
                      name="resumeFile"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                  <div className="mt-8">
                    <div className="flex">
                      <h4 className="text-lg md:text-xl">
                        Basic Info
                      </h4>
                    </div>
                    <div className="md:flex mt-4">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          Full Name <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="name"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="name"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pl-2">
                        <label className="block text-black-2">
                          Email <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="email"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="email"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          Mobile <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="phone"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          onPaste={validateOnPaste}
                          onKeyPress={validateOnNumber}
                          maxLength="10"
                        />
                        <ErrorMessage
                          name="phone"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex">
                      <h4 className="text-lg md:text-xl">
                        Professional Details
                      </h4>
                    </div>
                    <div className="md:flex mt-4">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          Current Job Title{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="currentEmployer"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="currentEmployer"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pl-2">
                        <label className="block text-black-2">
                          Designation <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="currentDesignation"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="currentDesignation"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex mt-4">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          Current Salary <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="currentSalary"
                          as="select"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        >
                          <option value="">Select a budget range</option>
                          {budgetRanges.map(({ name, value }) => (
                            <option
                              key={value}
                              value={value}
                            >
                              {name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="currentSalary"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pl-2">
                        <label className="block text-black-2">
                          Expected Salary{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="expectedSalary"
                          as="select"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        >
                          <option value="">Select a budget range</option>
                          {budgetRanges.map(({ name, value }) => (
                            <option
                              key={value}
                              value={value}
                            >
                              {name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="expectedSalary"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex mt-4">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          Notice Period <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="noticePeriod"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="noticePeriod"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pl-2">
                        <label className="block text-black-2">
                          Current Location{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <Field
                          name="currentLocation"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                        <ErrorMessage
                          name="currentLocation"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <h4 className="text-lg md:text-xl">
                      Social Network
                    </h4>
                    <div className="md:flex mt-4">
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pr-2">
                        <label className="block text-black-2">
                          LinkedIn Url
                        </label>
                        <Field
                          name="linkedInUrl"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-5 md:pl-2">
                        <label className="block text-black-2">Github Url</label>
                        <Field
                          name="gitHubUrl"
                          type="text"
                          className="mt-2 border border-grey-9 w-full p-3 rounded-md outline-none focus:border-secondary"
                          maxLength="50"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="md:flex">
                      <div className="text-lg w-full md:w-1/2 mb-4 md:pr-2">
                        <label className="inline-flex items-center">
                          <Field
                            type="checkbox"
                            name="isAgreeTerms"
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2">Agree to Terms</span>
                        </label>
                        <ErrorMessage
                          name="isAgreeTerms"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-4 md:pl-2">
                        <label className="inline-flex items-center">
                          <Field
                            type="checkbox"
                            name="isAgreePrivacy"
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2">Agree to Privacy Policy</span>
                        </label>
                        <ErrorMessage
                          name="isAgreePrivacy"
                          className="text-red-500 text-xs"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <div className="md:flex">
                      <div className="text-lg w-full md:w-1/2 mb-4 md:pr-2">
                        <label className="inline-flex items-center">
                          <Field
                            type="checkbox"
                            name="isImmediateJoining"
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2">Immediate Joining</span>
                        </label>
                      </div>
                      <div className="text-lg w-full md:w-1/2 mb-4 md:pl-2">
                        <label className="inline-flex items-center">
                          <Field
                            type="checkbox"
                            name="isReadyToRelocate"
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2">Ready to Relocate</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-end mt-4">
                    <button
                      type="reset"
                      className="mx-1 bg-red-500 text-white rounded-xl text-xl md:text-xl py-2.5 px-8 hover:opacity-70"
                      disabled={isLoading}
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      className="mx-1 bg-primary text-white rounded-xl text-xl md:text-xl py-2.5 px-8 hover:opacity-70"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitted..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default JobApplication;
