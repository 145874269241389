import React from "react";
import spotlightVector from "assets/new-images/spotlight-vector.png";

const EmployeeSpotlight = () => {
  return (
    <section className="pt-10 relative z-10">
      <div className="py-10 bg-blue-dark relative">
        <div className="py-10 bg-blue-dark relative">
          <div className="absolute inset-0 overflow-hidden">
            <img
              src={spotlightVector}
              alt=""
              className="w-full hidden md:block"
            />
          </div>
          <div className="sm:w-[80%] mx-auto px-4 relative translate-y-[50px]">
            <div
              className="text-center"
              data-aos="fade-up"
            >
              <p
                className="text-2xl md:text-3xl text-white"
                style={{ marginBottom: "100px" }}
              >
                Join Vivcloud Tech <br />
                Find us on any of these platforms,we respond 1-2 business days.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeSpotlight;
