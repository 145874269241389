import React, { useEffect } from "react";
import AOS from "aos";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import GoogleAnalyticsScript from "components/GoogleAnalyticsScript";
import Routers from "./routes/Routers";
import "aos/dist/aos.css";
import "./App.css";

const CloseButton = ({ closeToast }) => (
  <i
    onClick={closeToast}
    className="la la-close notifications-close"
  />
);
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <HelmetProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeButton={<CloseButton />}
      />
      <GoogleAnalyticsScript />
      <Routers />
    </HelmetProvider>
  );
}
export default App;
