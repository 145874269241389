import serviceBg from "assets/new-images/services_bg.png";
import BannerImage from "assets/new-images/web_banner.png";
import SuccessStoryImage1 from "assets/new-images/success_story1.png";
import SuccessStoryImage2 from "assets/new-images/success_story2.png";
import Newsletter from "components/Newsletter";

const WebDevelopment = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <h1
                className="text-4xl md:text-6xl font-semibold text-white text-center"
                data-aos="fade-up"
              >
                Web Design and Development
              </h1>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={BannerImage}
                alt=""
                className="mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Service Details */}
      <section className="py-20">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Our Expertise
            </h2>
            <p data-aos="fade-up">
              In the ever-evolving digital landscape, web development stands as
              the foundation that supports and advances the online presence of
              businesses. It is the art and science of building the core
              structure of websites and applications, ensuring they are robust,
              scalable, and future-proof.
            </p>
            <ul className="list-disc ml-10">
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">The Artisans of the Web: :</strong>
                Our web developers are the artisans of the digital era,
                meticulously crafting the infrastructure that underpins the
                sleek designs and user interfaces of your online platforms. They
                are the unseen architects, building the frameworks and
                functionalities that allow for an agile, dynamic web experience.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">Front-End Engineering:</strong>
                Front-end development is where the visual design leaps into
                action. Our developers harness HTML, CSS, and JavaScript to
                breathe life into the designer's vision, creating responsive,
                interactive web pages that delight users across all devices.
                We're not just coding; we're setting the stage for every user
                interaction, every engaging element, ensuring your site is as
                pleasing to use as it is to view.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">Back-End Engineering:</strong>
                Behind every seamless user experience is a robust back-end
                architecture. Our back-end development expertise lies in
                creating the powerhouse that drives your site – from
                sophisticated server-side logic to database management. We
                utilize an array of back-end languages, including PHP, Ruby,
                Python, and Node.js, to construct a solid foundation for your
                digital platforms.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Full-Stack Development – The Complete Package:
                </strong>
                For those who need it all, our full-stack developers are the
                multi-talented experts who have a handle on both the front-end
                and back-end. They possess a panoramic view of web development,
                ensuring every line of code contributes to a cohesive,
                integrated user experience from start to finish.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Agile Development for a Fast-Paced World:
                </strong>
                We embrace agile methodologies to stay adaptive and responsive
                in a fast-paced digital world. Our iterative approach means your
                web project is flexible, evolving with user feedback and
                business needs. This means quicker launches, faster iteration,
                and a product that's finely tuned to your market.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">Building Beyond Aesthetics:</strong>
                At the heart of our web development philosophy is a commitment
                to functionality and performance. We build not just for the now,
                but with an eye on the future, ensuring that your digital
                presence is equipped to adapt and grow with your business.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Your Digital Partner for Growth:
                </strong>
                Consider us your digital partner, committed to developing
                solutions that drive growth, improve user engagement, and
                enhance overall digital performance. Let's build the bedrock of
                your online presence together and set the stage for your digital
                success.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Success Story */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Success Story
            </h2>
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage1}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How AI breakthrough to the Future?
                  </h3>
                </div>
              </div>
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage2}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How Technology emerging in 2023?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default WebDevelopment;
