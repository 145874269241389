import serviceBg from "assets/new-images/services_bg.png";
import BannerImage from "assets/new-images/software_testor.png";
import SuccessStoryImage1 from "assets/new-images/success_story1.png";
import SuccessStoryImage2 from "assets/new-images/success_story2.png";
import Newsletter from "components/Newsletter";

const SoftwareTestingService = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <h1
                className="text-4xl md:text-6xl font-semibold text-white text-center"
                data-aos="fade-up"
              >
                Software Testing Service
              </h1>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={BannerImage}
                alt=""
                className="mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Service Details */}
      <section className="py-20">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Our Expertise
            </h2>
            <p data-aos="fade-up">
              In the digital realm, the margin for error is slim, and the demand
              for perfection is high. Our software testing services stand as the
              guardian of quality, ensuring that your software performs
              flawlessly, meets end-user expectations, and stands resilient in
              the face of ever-evolving technology challenges.
            </p>
            <ul className="list-disc ml-10">
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Comprehensive Quality Assurance:
                </strong>
                Quality isn't just a goal; it's a guarantee. Our comprehensive
                quality assurance process delves deep into your software's
                functionality, performance, and usability. We go beyond finding
                bugs—we provide solutions and strategies that elevate the
                quality of your product, ensuring a superior user experience.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Automated Testing for Swift Precision:
                </strong>
                Embrace the speed and accuracy of automated testing. Our suite
                of automated testing tools and frameworks accelerates the
                testing process, delivering faster results without compromising
                on detail or depth. With automation, we ensure consistent
                testing across every iteration of your product.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Manual Testing for the Human Touch:
                </strong>
                Some aspects of software need the discerning eye of a human
                tester. Our manual testing services focus on those areas where
                human intuition and insight are crucial, providing a layer of
                testing that ensures your software feels right to the end-user.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Performance Testing for Unshakable Stability:
                </strong>
                Robust software must withstand the pressures of real-world use.
                Our performance testing rigorously evaluates your software under
                varying degrees of load and stress, ensuring it remains stable
                and responsive when it matters most.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Security Testing for Uncompromised Integrity:
                </strong>
                In a world of digital threats, security is paramount. Our
                security testing identifies vulnerabilities and strengthens your
                software's defenses, protecting your data and your users from
                both common and sophisticated cyber threats.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Usability Testing for Intuitive Experiences:
                </strong>
                Usability is the bridge between functionality and user
                satisfaction. Our usability testing assesses the end-user
                experience, ensuring that your software is not only powerful but
                also intuitive, engaging, and accessible.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Continuous Integration and Deployment:
                </strong>
                Stay agile and responsive with our CI/CD integration services,
                where testing is a continuous part of development and
                deployment. This approach ensures that quality is baked into
                every stage of the software lifecycle, from initial coding
                through to final release.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Success Story */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Success Story
            </h2>
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage1}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How AI breakthrough to the Future?
                  </h3>
                </div>
              </div>
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage2}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How Technology emerging in 2023?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default SoftwareTestingService;
