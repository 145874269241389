import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import Home from "pages/Home";
import About from "pages/About";
import ApplicationDevelopment from "pages/Services/ApplicationDevelopment";
import ContentCreator from "pages/Services/ContentCreator";
import SoftwareTestingService from "pages/Services/SoftwareTestingService";
import UiUxDesign from "pages/Services/UiUxDesign";
import WebDevelopment from "pages/Services/WebDevelopment";
import Careers from "pages/Career";
import Contact from "pages/Contact";
import Blog from "pages/Blog";
import BlogDetails from "pages/Blog/BlogDetails";
import Industry from "pages/Industry";
import JobListing from "pages/Career/JobListing";
import JobDetails from "pages/Career/JobDetails";
import JobApplication from "pages/Career/JobApplication";
import FileUpload from "pages/FileUpload";

function Routers() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/about"
          element={<About />}
        />
        <Route
          path="/service/application-development"
          element={<ApplicationDevelopment />}
        />
        <Route
          path="/service/content-creator"
          element={<ContentCreator />}
        />
        <Route
          path="/service/software-testing-service"
          element={<SoftwareTestingService />}
        />
        <Route
          path="/service/ui-ux-design"
          element={<UiUxDesign />}
        />
        <Route
          path="/service/web-development"
          element={<WebDevelopment />}
        />
        <Route
          path="/career"
          element={<Careers />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route
          path="/blog"
          element={<Blog />}
        />
        <Route
          path="/blog/:blogId"
          element={<BlogDetails />}
        />
        <Route
          path="/industry"
          element={<Industry />}
        />
        <Route
          path="/career/jobs"
          element={<JobListing />}
        />
        <Route
          path="/career/jobs/:jobId"
          element={<JobDetails />}
        />
        <Route
          path="/career/jobs/:jobId/job-application"
          element={<JobApplication />}
        />
        <Route
          path="/file-upload"
          element={<FileUpload />}
        />
        <Route
          path="*"
          element={<Home />}
        />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default Routers;
