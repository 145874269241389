import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description }) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Vivcloud Technologies",
    description:
      "Partner with Vivcloud Technologies for innovative IT solutions that drive business growth.",
    url: "https://vivcloudtech.com",
    image: `${window.location.origin}/logo.png`,
    telephone: "+91 86040 42743",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Office no-14 & 26, Jeevan shopping center, Viraj Khand",
      addressLocality: "Gomti nagar",
      addressRegion: "Lucknow",
      postalCode: "226010",
      addressCountry: "India",
    },
    openingHours: "Mo-Fri 09:00-18:00",
    geo: {
      "@type": "GeoCoordinates",
      latitude: "26.843680",
      longitude: "81.011620",
    },
    sameAs: [
      "https://www.facebook.com/people/Vivcloud-Technologies-Pvt-Ltd/100085157191155",
      "https://twitter.com/VIVCLOUDTECH",
      "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGYksSzeLC2LwAAAYoe7kuA_TZR8M_GpLdWO1TuIcZjwr9uHowi7LnbiHv5qOlHw69sQMkLpW9R8uXqj-iUOzoJEhG6aElq2c4gQO2l4Z4NPrT1RXugf6_t3lhWwvb1EzyMO80=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fvivcloud-technologies-pvt-ltd",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SEO;
