import React from "react";

export default function FileUpload() {
  const onFileChange = async (e, name) => {
    const file = e.target.files[0];
    if (file) {
      try {
        let byteArray = await fileToByteArray(file);
        console.log(byteArray);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fileToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let fileByteArray = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target.readyState === FileReader.DONE) {
            let arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (const byte of array) {
              fileByteArray.push(byte);
            }
            resolve(fileByteArray);
          }
        };
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <div className="container flex justify-center items-center h-screen w-full bg-gray-100">
      <input
        type="file"
        accept="image/*"
        onChange={(e) => onFileChange(e, "vendorLogo")}
      />
    </div>
  );
}
