import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { FaFacebook, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import Newsletter from "components/Newsletter";
import Accordion from "pages/Career/Accordion";
import { getJobByIdApi } from "service";

const JobDetails = () => {
  const { jobId } = useParams();
  const [jobInfo, setJobInfo] = useState({
    loading: true,
    data: {},
  });
  const [jobFaqStructure, setJobFaqStructure] = useState([]);

  const loadJobById = useCallback(async () => {
    try {
      const resp = await getJobByIdApi(jobId);
      setJobInfo({ loading: false, data: resp.data || {} });
    } catch {
      setJobInfo({ loading: false, data: {} });
    }
  }, [jobId]);

  useEffect(() => {
    loadJobById();
  }, [loadJobById]);

  useEffect(() => {
    if (jobInfo.loading) return;
    setJobFaqStructure([
      {
        id: 1,
        header: "Description",
        text: jobInfo.data?.jobDescription,
      },
      {
        id: 2,
        header: "Job Type",
        text: jobInfo.data?.jobType,
      },
      {
        id: 3,
        header: "Skills",
        text: jobInfo.data?.jobSkills,
      },
      {
        id: 4,
        header: "Responsibilities",
        text: jobInfo.data?.jobResponsibilities,
      },
      {
        id: 5,
        header: "Qualifications",
        text: jobInfo.data?.jobQualifications,
      },
      {
        id: 6,
        header: "Experience",
        text: jobInfo.data?.jobExperience,
      },
      {
        id: 7,
        header: "Salary",
        text: jobInfo.data?.salary,
      },
    ]);
  }, [jobInfo]);

  return (
    <>
      <section className="py-20 bg-blue-dark min-h-[400px] flex items-center justify-center">
        <div className="w-full sm:w-[80%] mx-auto px-4 relative">
          <div className="text-white text-right">
            <h1 className="text-4xl md:text-5xl font-semibold">
              {jobInfo.data?.jobTitle}
            </h1>
            <div className="flex items-center justify-end mt-7">
              <p className="text-lg md:text-xl font-semibold">
                Share this Job
              </p>
              <ul className="flex items-center pl-2">
                <li>
                  <a
                    aria-label="facebook"
                    href="https://www.facebook.com/people/Vivcloud-Technologies-Pvt-Ltd/100085157191155/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaFacebook className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="twitter"
                    href="https://twitter.com/VIVCLOUDTECH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaTwitterSquare className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="linkedin"
                    href="https://www.linkedin.com/company/vivcloud-technologies-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaLinkedin className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Job Details */}
      <section className="py-14">
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="mt-5 md:mt-10">
            {jobFaqStructure.map((jobFaq, index) => (
              <Accordion
                key={index}
                jobFaq={jobFaq}
              />
            ))}
            <Link
              className="inline-block bg-primary text-white mt-10 xl:mt-20 rounded-xl text-xl md:text-2xl py-2.5 px-10 hover:opacity-80"
              to={`/career/jobs/${jobId}/job-application`}
            >
              Apply
            </Link>
          </div>
        </div>
      </section>
      <EmployeeSpotlight />
      <Newsletter />
    </>
  );
};

export default JobDetails;
