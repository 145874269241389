import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "assets/new-images/footer-logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import "./style.css";
import { IoArrowUpCircleOutline } from "react-icons/io5";

const Footer = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer className="text-grey pt-10">
        <div className="sm:w-[95%] mx-auto px-4">
          <div className="flex flex-wrap">
            <div
              className="w-full lg:w-2/5 py-5 lg:py-0"
              data-aos="fade-up"
            >
              <img
                src={FooterLogo}
                alt=""
                className="mb-5"
              />
              <p className="lg:w-[300px] text-md tracking-tight mb-5 text-primary">
                Your true software development partner and technology expert
                accelerating your business efficiency.
              </p>
              <ul className="flex items-center">
                <li>
                  <a
                    aria-label="facebook"
                    href="https://www.facebook.com/people/Vivcloud-Technologies-Pvt-Ltd/100085157191155/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-light w-[34px] h-[34px] rounded-full bg-grey-light inline-flex items-center justify-center shadow-[0_4px_14px_0px_rgba(0,0,0,0.15)] mr-4 hover:border"
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="instagram"
                    href="https://www.instagram.com/vivcloudtech/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-light w-[34px] h-[34px] rounded-full bg-grey-light inline-flex items-center justify-center shadow-[0_4px_14px_0px_rgba(0,0,0,0.15)] mr-4 hover:border"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="twitter"
                    href="https://twitter.com/VIVCLOUDTECH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-light w-[34px] h-[34px] rounded-full bg-grey-light inline-flex items-center justify-center shadow-[0_4px_14px_0px_rgba(0,0,0,0.15)] mr-4 hover:border"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="linkedin"
                    href="https://www.linkedin.com/company/vivcloud-technologies-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-light w-[34px] h-[34px] rounded-full bg-grey-light inline-flex items-center justify-center shadow-[0_4px_14px_0px_rgba(0,0,0,0.15)] mr-4 hover:border"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="w-1/2 md:w-1/3 lg:w-1/5 pl-3"
              data-aos="fade-up"
            >
              <p className="text-2xl font-semibold pb-1 text-primary">Menu</p>
              <div className="w-[50px] h-[2px] bg-secondary mb-7"></div>
              <ul>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/contact"
                  >
                    Contacts
                  </Link>
                </li>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/career"
                  >
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="w-1/2 md:w-1/3 lg:w-1/5	 pl-3"
              data-aos="fade-up"
            >
              <p className="text-2xl font-semibold pb-1 text-primary">
                Services
              </p>
              <div className="w-[50px] h-[2px] bg-secondary mb-7"></div>
              <ul>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/privacy-policy"
                  >
                    Privacy Policy{" "}
                  </Link>
                </li>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/term-of-use"
                  >
                    Terms of Use
                  </Link>
                </li>
                <li className="text-md mb-5">
                  <Link
                    className="text-primary hover:text-secondary font-semibold"
                    to="/support"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="w-full md:w-1/3 lg:w-1/5 md:pl-3 pt-5 md:pt-0"
              data-aos="fade-up"
            >
              <p className="text-2xl font-semibold pb-1 text-primary">
                Contacts
              </p>
              <div className="w-[50px] h-[2px] bg-secondary mb-7"></div>
              <ul>
                <li className="text-md mb-2">
                  <div className=" text-primary">
                    <strong className="pr-2">Address:</strong>
                    <span className="font-normal">
                      Office no-14 & 26, Jeevan shopping center, Viraj Khand,
                      Gomti nagar, Lucknow 226010 (India)
                    </span>
                  </div>
                </li>
                <li className="text-md mb-2">
                  <div className="text-primary">
                    <strong className="pr-2">Email:</strong>
                    <a
                      className="hover:text-secondary font-normal"
                      href="mailto:vivcloudtech@gmail.com"
                      rel="noopener noreferrer"
                    >
                      vivcloudtech@gmail.com
                    </a>
                  </div>
                </li>
                <li className="text-md mb-2">
                  <div className="text-primary">
                    <strong className="pr-2">Phone:</strong>
                    <a
                      className="hover:text-secondary font-normal"
                      href="tel:+918604042743"
                      rel="noopener noreferrer"
                    >
                      (+91) 8604 0427 43
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-[80%] mx-auto mt-8 h-[5px] bg-blue-light"></div>
          <p className="text-center py-10 text-primary">
            Copyright &copy; {new Date().getFullYear()} by VIVCLOUD TECHNOLOGIES
            PRIVATE LIMITED
          </p>
        </div>
      </footer>
      {showBackToTop && (
        <button
          className="fixed right-3.5 bottom-[6rem] text-[40px] bg-primary rounded-full text-white flex items-center justify-center p-[5px] z-20 hover:animate-bounce"
          onClick={handleBackToTop}
        >
          <IoArrowUpCircleOutline />
        </button>
      )}
    </>
  );
};

export default Footer;
