import { baseURL } from "../config";
import axios from "axios";

export const addContactApi = (payload) => {
  return axios.post(`${baseURL}/api/v1/enquiry/contactUs`, payload);
};

export const createSubscribeApi = (payload) => {
  return axios.post(`${baseURL}/api/v1/newsletter/subscribe`, payload);
};

export const getJobsApi = (param) => {
  return axios.get(`${baseURL}/api/v1/openings`, { params: param });
};

export const getJobByIdApi = (jobId) => {
  return axios.get(`${baseURL}/api/v1/openings/${jobId}`);
};

export const applyJobsApi = (payload) => {
  return axios.post(`${baseURL}/api/v1/candidate`, payload);
};

export const getBlogsApi = (param) => {
  return axios.get(`${baseURL}/api/v1/blog`, { params: param });
};

export const getBlogByIdApi = (blogId) => {
  return axios.get(`${baseURL}/api/v1/blog/${blogId}`);
};

export const getTeamsApi = (param) => {
  return axios.get(`${baseURL}/api/v1/team`, { params: param });
};
