import IndustryBackgroundImage from "assets/new-images/industry_bg.png";
import latestThinking1 from "assets/new-images/success_story1.png";
import DashboardImage from "assets/new-images/dashboard.png";
import EmployeeSpotlight from "components/EmployeeSpotlight";

const Industry = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-20 relative overflow-hidden min-h-[400px] flex items-center justify-center">
        <img
          src={IndustryBackgroundImage}
          alt=""
          className="absolute inset-0 object-cover h-full w-full"
        />
        <div className="absolute inset-0 bg-blue-dark opacity-70"></div>
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div
            className="text-center text-white"
            data-aos="fade-up"
          >
            <h1 className="text-5xl md:text-6xl font-semibold">
              Independent Software
            </h1>
            <p className="md:text-lg pt-7">
              Empowering to deliver innovative products using cutting-edge
              technologies. <br />
              Stay updated on web technologies, frameworks, and best practices
              for building websites.
            </p>
          </div>
        </div>
      </section>
      {/* industry dashboard section */}
      <section className="py-14 bg-black-default text-white">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-center">
            <h2
              className="text-4xl md:text-6xl font-semibold mt-5"
              data-aos="fade-up"
            >
              Build with the power of code — without writing any
            </h2>
            <p
              className="md:text-lg mt-16"
              data-aos="fade-up"
            >
              Take control of HTML, CSS, and JavaScript in a visual canvas.
              Webflow generates clean, semantic code that’s ready to publish or
              hand to developers.
            </p>
          </div>
        </div>
        <div>
          <img
            src={DashboardImage}
            alt=""
            className="mx-auto"
            data-aos="fade-up"
          />
        </div>
      </section>
      {/* Our Latest Thinking */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="">
            <div
              className="w-[69px] h-[5px] bg-blue-light"
              data-aos="fade-right"
            ></div>
            <h2
              className="text-4xl md:text-5xl font-semibold mt-5 text-primary"
              data-aos="fade-up"
            >
              Our Latest Thinking
            </h2>
          </div>
          <div className="flex flex-wrap">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                className="w-full md:w-1/2 lg:w-1/3 px-4 mt-7"
                data-aos="fade-up"
                key={index}
              >
                <div className="rounded-2xl hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out group border border-grey-light">
                  <div className="relative rounded-2xl overflow-hidden">
                    <img
                      src={latestThinking1}
                      alt=""
                      className="w-full h-full object-cover group-hover:scale-125 duration-500 ease-in-out"
                    />
                    <div className="flex justify-between absolute bottom-5 left-5 bg-white rounded-full text-gray-5 text-sm md:text-base py-3 px-7 right-5">
                      <span>Oct 05, 2023</span>
                      <span>Ram Vishwa</span>
                    </div>
                  </div>
                  <div className="mt-6 p-6 border-t border-grey-light">
                    <h3 className="text-xl md:text-2xl font-semibold text-black-1">
                      How AI breakthrough to the Future?
                    </h3>
                    <p className="md:text-lg text-gray-5 mt-5">
                      Lorem Ipsum is simply dummy text the printing and
                      typesetting industry.
                    </p>
                    <button className="md:text-lg text-black-1 mt-4 hover:text-secondary duration-500 ease-in-out">
                      View More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <EmployeeSpotlight />
    </>
  );
};

export default Industry;
