import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Newsletter from "components/Newsletter";
import serviceBg from "assets/new-images/career_bg.png";
import WorkWithUSVector from "assets/new-images/work_with_us_vector.png";
import WorkWithUS from "assets/new-images/work_with_us.png";
import StripeUp from "assets/new-images/stripe_up.png";
import StripeDown from "assets/new-images/stripe_down.png";
import { FaArrowRight } from "react-icons/fa";
import "./style.css";

const Careers = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden min-h-[400px] flex items-center justify-center">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="absolute inset-0 bg-blue-dark opacity-70"></div>
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div
              className="md:w-1/2 md:pr-3"
              data-aos="fade-right"
            >
              <h1 className="text-4xl md:text-5xl font-semibold text-white">
                ACCELERATE YOUR GROWTH WITH VIVCLOUD
              </h1>
              <button
                onClick={() => navigate("/about")}
                className="mt-10 text-lg md:text-xl text-white text-bold flex items-center"
              >
                Learn More{" "}
                <span className="ml-2 w-[45px] h-[45px] rounded-full bg-gradient-to-tr to-button-gradiant-1 from-button-gradiant-2 flex items-center justify-center">
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate("/contact")}
          data-aos="fade-left"
          className="text-white bg-gradient-to-tr to-button-gradiant-1 from-button-gradiant-2 hover:from-button-gradiant-1 hover:to-button-gradiant-2 md:text-lg font-bold py-2.5 px-6 absolute right-0 bottom-0 rounded-l-lg"
        >
          Contact
        </button>
      </section>
      {/* Work From Home */}
      <section className="py-14">
        <div
          className="max-w-[1050px] mx-auto px-4"
          data-aos="fade-up"
        >
          <h2 className="text-center text-4xl md:text-5xl font-semibold">
            WORK FROM OFFICE, WORK FROM HOME, WORK FROM ANYWHERE!
          </h2>
        </div>
      </section>
      {/* Work with us */}
      <section className="py-14">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="md:flex items-center">
            <div className="md:w-3/5  md:pr-3 relative">
              <img
                src={WorkWithUSVector}
                alt=""
                className="absolute top-[-30px] left-0"
              />
              <img
                src={WorkWithUS}
                alt=""
                className="relative pl-5 md:pl-10 xl:pl-20"
                data-aos="fade-right"
              />
            </div>
            <div
              className="md:w-2/5 pt-7 md:pt-0 md:pl-3"
              data-aos="fade-up"
            >
              <p className="text-md md:text-md mt-5">
                Vivcloud is not only a hub for creative individuals but also a
                place where talent thrives and collaboration flourishes. Join
                our diverse team to embark on a rewarding journey of growth,
                innovation, and meaningful impact. Discover exciting career
                opportunities at Vivcloud and be a part of a team that makes a
                difference.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Call To Action */}
      <section className="py-14 bg-gradient-to-tr to-grey-1 from-blue-4 mb-20 overflow-hidden">
        <div className="w-[80%] mx-auto px-4">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3 ">
              <h3
                className="text-3xl md:text-4xl leading-tight font-bold"
                data-aos="fade-right"
              >
                Hire the best developers and designers around!
              </h3>
            </div>
            <div
              className="md:w-1/2 pt-7 md:pt-0 md:pl-3 flex justify-end"
              data-aos="fade-left"
            >
              <div className="flex flex-col items-center">
                <img
                  src={StripeUp}
                  alt=""
                  className="mb-2"
                />
                <Link
                  to="/career/jobs"
                  className="text-white bg-gradient-to-tr to-button-gradiant-1 from-button-gradiant-2 hover:from-button-gradiant-1 hover:to-button-gradiant-2 text-lg md:text-xl font-bold py-4 px-10 rounded-md"
                >
                  Hire Top Developers
                </Link>
                <img
                  src={StripeDown}
                  alt=""
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default Careers;
