import { useRef, useState } from "react";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";

const Accordion = ({ jobFaq }) => {
  const contentEl = useRef();
  const { id, header, text } = jobFaq;
  const [active, setActive] = useState(id);

  const handleToggle = (id) => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  };

  return (
    <div className="border-b border-grey-9 py-5 md:py-10">
      <div className="">
        <div
          className={`flex items-center justify-between  ${
            active === id ? "pb-6" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="text-2xl md:text-3xl font-semibold">
            {header}
          </h5>
          {active === id ? (
            <CiCircleMinus className="w-[30px] h-[30px] text-secondary" />
          ) : (
            <CiCirclePlus className="w-[30px] h-[30px] text-blue-light" />
          )}
        </div>
      </div>
      {active === id && (
        <div
          ref={contentEl}
          className={`px-12`}
        >
          <div className="rc-accordion-body">
            <p className="text-lg md:text-xl text-green-1">
              {text}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
