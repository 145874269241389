import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import blogDetailsBg from "assets/new-images/industry_bg.png";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import {
  FaArrowRight,
  FaFacebook,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import { getBlogByIdApi, getBlogsApi } from "service";
import { dataURItoBlob } from "utils";
import "../style.css";

const BlogDetails = () => {
  const { blogId } = useParams();
  const [blogInfo, setBlogInfo] = useState({
    loading: true,
    data: {},
  });
  const [recentBlogs, setRecentBlogs] = useState({
    loading: true,
    data: [],
  });

  const loadBlogById = useCallback(async () => {
    try {
      const resp = await getBlogByIdApi(blogId);
      setBlogInfo({ loading: false, data: resp.data || {} });
    } catch {
      setBlogInfo({ loading: false, data: {} });
    }
  }, [blogId]);

  useEffect(() => {
    loadBlogById();
  }, [loadBlogById]);

  const loadRecentBlogs = async () => {
    const param = {
      page: 0,
      limit: 5,
    };
    try {
      const resp = await getBlogsApi(param);
      setRecentBlogs({ loading: false, data: resp.data || [] });
    } catch {
      setRecentBlogs({ loading: false, data: [] });
    }
  };

  useEffect(() => {
    loadRecentBlogs();
  }, []);

  function generateAvatarInitials(fullName) {
    if (!fullName) return "";
    const nameParts = fullName.trim().split(/\s+/);
    const initials =
      nameParts.length > 1
        ? nameParts[0][0].toUpperCase() +
          nameParts[nameParts.length - 1][0].toUpperCase()
        : nameParts[0][0].toUpperCase();
    return initials;
  }

  return (
    <>
      {/* Banner */}
      <section className="pt-20 pb-[150px] bg-blue-dark overflow-hidden relative min-h-[400px] flex items-center justify-center">
        <img
          src={blogDetailsBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full"
        />
        <div className="absolute inset-0 bg-blue-dark opacity-90"></div>
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="text-white">
            <h1 className="text-4xl md:text-5xl font-semibold">
              {blogInfo.data?.title}
            </h1>
            <div className="flex items-center mt-7">
              <p className="text-lg md:text-xl font-semibold">
                Share this post
              </p>
              <ul className="flex items-center pl-2">
                <li>
                  <a
                    aria-label="facebook"
                    href="https://www.facebook.com/people/Vivcloud-Technologies-Pvt-Ltd/100085157191155/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaFacebook className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="twitter"
                    href="https://twitter.com/VIVCLOUDTECH"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaTwitterSquare className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="linkedin"
                    href="https://www.linkedin.com/company/vivcloud-technologies-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 block"
                  >
                    <FaLinkedin className="w-[24px] h-[24px] text-blue-5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Blog Section */}
      <section className="py-14">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-8/12 bg-white rounded-2xl p-10 mt-[-120px] z-10 drop-shadow-[0_69px_42px_rgba(200,200,200,0.25)]">
              <div className="flex">
                <span className="w-[50px] h-[50px] flex items-center justify-center text-white bg-primary rounded-full mr-3">
                  {generateAvatarInitials(blogInfo.data?.blogAuthor?.name)}
                </span>
                <div>
                  <h6 className="text-lg md:text-xl">
                    {blogInfo.data?.blogAuthor?.name}
                  </h6>
                  {blogInfo.data?.createdDate && (
                    <p>
                      {moment(blogInfo.data?.createdDate).format(
                        "DD, MMMM YYYY"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="md:text-lg text-gray-5 mt-5">
                <div
                  dangerouslySetInnerHTML={{ __html: blogInfo.data?.content }}
                ></div>
              </div>
            </div>
            <div className="w-full md:w-1/3 pt-5 md:pt-0 md:pl-8">
              <h4 className="text-2xl font-semibold mb-10 text-primary">
                Recent Blogs
              </h4>
              <div
                className="mt-7"
                data-aos="fade-up"
              >
                {recentBlogs.data.map((blog) => (
                  <div
                    key={blog.id}
                    className="duration-500 ease-in-out group border border-grey-light shadow-lg rounded-md hover:shadow-2xl"
                  >
                    <div className="relative rounded-2xl overflow-hidden">
                      <img
                        src={dataURItoBlob(blog.thumbnailPhoto)}
                        alt=""
                        className="w-full h-full object-cover group-hover:scale-125 duration-500 ease-in-out"
                      />
                    </div>
                    <div className="px-5 pb-5">
                      <h3 className="text-md md:text-lg font-semibold text-black-1 mt-5">
                        {blog.title}
                      </h3>
                      <p className="text-sm md:text-md text-grey-8 mt-2">
                        {blog.description}
                      </p>
                      <Link
                        to={`/blog/${blog.id}`}
                        className="md:text-md text-black-1 mt-4 hover:text-secondary flex items-center justify-end"
                      >
                        View More <FaArrowRight className="ml-2" />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <EmployeeSpotlight />
    </>
  );
};

export default BlogDetails;
