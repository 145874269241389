import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "assets/new-images/footer-logo.png";
import { IoMdMenu } from "react-icons/io";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import "./style.css";

const Header = () => {
  const ref = useRef();
  const [menu, setMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const shouldRenderComponent = windowWidth <= 1024;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getActiveClass = (isActive) =>
    isActive
      ? "nav-menu text-secondary hover:text-secondary duration-500 ease-in-out font-semibold"
      : "nav-menu text-primary hover:text-secondary duration-500 ease-in-out font-semibold";

  const getServiceActiveClass = (isActive) =>
    isActive
      ? "dropdown nav-menu text-secondary hover:text-secondary duration-500 ease-in-out border-b border-grey-9 block px-5 py-4 md:text-md font-semibold"
      : "dropdown nav-menu text-primary hover:text-secondary duration-500 ease-in-out border-b border-grey-9 block px-5 py-4 md:text-md font-semibold";

  return (
    <>
      <div className="h-[75px]"></div>
      <header
        className={`py-3 bg-white w-full duration-500 ease-in-out top-0 left-0 z-20 fixed`}
        ref={ref}
      >
        <div className="sm:w-[95%] mx-auto px-4">
          <div className="flex items-center justify-between relative">
            <div className="header-logo">
              <Link to="/">
                <img
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <IoMdMenu
              className="lg:hidden inline-block w-[20px] h-[20px]"
              onClick={() => setMenu(!menu)}
            />
            <ul
              className={`${
                menu ? "block" : shouldRenderComponent && "hidden"
              } flex-grow lg:flex items-center justify-center max-lg:absolute max-lg:top-[100%] max-lg:bg-white max-lg:z-20 max-lg:w-full `}
            >
              <li
                className={`text-base md:text-md text-grey-dark px-3 lg:px-7 max-lg:border-b max-lg:border-grey-9 max-lg:block max-lg:px-5 max-lg:py-4`}
              >
                <NavLink
                  to="/about"
                  className={({ isActive }) => getActiveClass(isActive)}
                  onClick={() => setMenu(false)}
                >
                  About us
                </NavLink>
              </li>
              <li
                className={`group text-base md:text-md text-grey-dark px-3 lg:px-7 relative max-lg:border-b max-lg:border-grey-9 max-lg:block max-lg:px-5 max-lg:py-4`}
              >
                <span className="max-lg:flex max-lg:items-center font-semibold max-lg:justify-between max-lg:w-full text-primary hover:text-secondary duration-500 ease-in-out">
                  Services{" "}
                  <FaAngleUp className="hidden group-hover:inline-block" />
                  <FaAngleDown className="inline-block group-hover:hidden" />
                </span>
                <div className="overflow-hidden lg:absolute lg:top-[100%] max-lg:hidden group-hover:block group-hover:translate-y-[15px] translate-y-[20px] lg:left-0 bg-white lg:z-20 w-full lg:w-[270px] opacity-0 invisible  group-hover:visible group-hover:opacity-100 duration-500 ease-in-out mt-0 md:mt-2.5">
                  <NavLink
                    to="/service/ui-ux-design"
                    className={({ isActive }) =>
                      getServiceActiveClass(isActive)
                    }
                    onClick={() => setMenu(false)}
                  >
                    <span className="inline-block w-[10px] h-[10px] bg-secondary rounded-full mr-2"></span>
                    UI/UX Design
                  </NavLink>
                  <NavLink
                    to="/service/web-development"
                    className={({ isActive }) =>
                      getServiceActiveClass(isActive)
                    }
                    onClick={() => setMenu(false)}
                  >
                    <span className="inline-block w-[10px] h-[10px] bg-secondary rounded-full mr-2"></span>
                    Web Development
                  </NavLink>

                  <NavLink
                    to="/service/application-development"
                    className={({ isActive }) =>
                      getServiceActiveClass(isActive)
                    }
                    onClick={() => setMenu(false)}
                  >
                    <span className="inline-block w-[10px] h-[10px] bg-secondary rounded-full mr-2"></span>
                    Application Development
                  </NavLink>
                  <NavLink
                    to="/service/software-testing-service"
                    className={({ isActive }) =>
                      getServiceActiveClass(isActive)
                    }
                    onClick={() => setMenu(false)}
                  >
                    <span className="inline-block w-[10px] h-[10px] bg-secondary rounded-full mr-2"></span>
                    Software Testing Service
                  </NavLink>
                  <NavLink
                    to="/service/content-creator"
                    className={({ isActive }) =>
                      getServiceActiveClass(isActive)
                    }
                    onClick={() => setMenu(false)}
                  >
                    <span className="inline-block w-[10px] h-[10px] bg-secondary rounded-full mr-2"></span>
                    Content Creator
                  </NavLink>
                </div>
              </li>
              <li
                className={`text-base md:text-md text-grey-dark px-3 lg:px-7 max-lg:border-b max-lg:border-grey-9 max-lg:block max-lg:px-5 max-lg:py-4`}
              >
                <NavLink
                  to="/industry"
                  className={({ isActive }) => getActiveClass(isActive)}
                  onClick={() => setMenu(false)}
                >
                  Industry
                </NavLink>
              </li>
              <li
                className={`text-base md:text-md text-grey-dark px-3 lg:px-7 max-lg:border-b max-lg:border-grey-9 max-lg:block max-lg:px-5 max-lg:py-4`}
              >
                <NavLink
                  to="/blog"
                  className={({ isActive }) => getActiveClass(isActive)}
                  onClick={() => setMenu(false)}
                >
                  Blog
                </NavLink>
              </li>
              <li
                className={`text-base md:text-md text-grey-dark px-3 lg:px-7 max-lg:border-b max-lg:border-grey-9 max-lg:block max-lg:px-5 max-lg:py-4`}
              >
                <NavLink
                  to="/career"
                  className={({ isActive }) => getActiveClass(isActive)}
                  onClick={() => setMenu(false)}
                >
                  Career
                </NavLink>
              </li>
            </ul>
            <Link
              to="/contact"
              className="hidden lg:inline-block duration-500 ease-in-out bg-primary text-white rounded-md text-md py-2 px-3 hover:opacity-80"
              onClick={() => setMenu(false)}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </header>
      <a
        aria-label="whats-app"
        href="https://api.whatsapp.com/send?phone=918604042743"
        target="_blank"
        rel="noopener noreferrer"
        className="whats-app-pulse"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          size="sm"
        />
      </a>
    </>
  );
};

export default Header;
