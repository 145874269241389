import React from "react";
import serviceBg from "assets/new-images/contact_bg.png";
import ContactShadow from "assets/new-images/contact_shadow.png";
import contactFormImage from "assets/new-images/contact.png";
import { IoMdMail } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SEO from "components/SEO";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import Newsletter from "components/Newsletter";
import { addContactApi } from "service";
import { validateOnPaste, validateOnNumber } from "utils";
import countries from "constant/country";

const jobRoles = [
  {
    name: "Software Developer",
    value: "software_developer",
  },
  {
    name: "Web Designer",
    value: "web_designer",
  },
  {
    name: "Digital Marketing Specialist",
    value: "digital_marketing_specialist",
  },
  {
    name: "Product Manager",
    value: "product_manager",
  },
  {
    name: "Data Analyst",
    value: "data_analyst",
  },
  {
    name: "Graphic Designer",
    value: "graphic_designer",
  },
  {
    name: "User Experience Designer",
    value: "user_experience_designer",
  },
  {
    name: "Project Manager",
    value: "project_manager",
  },
  {
    name: "Content Writer",
    value: "content_writer",
  },
  {
    name: "SEO Specialist",
    value: "seo_specialist",
  },
];

const budgetRanges = [
  {
    name: "Below 5k",
    value: "Below_5k",
  },
  {
    name: "5k - 10k",
    value: "5k-10k",
  },
  {
    name: "10-k - 20k",
    value: "10k-20k",
  },
  {
    name: "20k - 30k",
    value: "20k-30k",
  },
  {
    name: "30k - 40k",
    value: "30k-40k",
  },
  {
    name: "40k - 50k",
    value: "40k-50k",
  },
  {
    name: "Above 50k",
    value: "Above_50k",
  },
];

const Contact = () => {
  const MySwal = withReactContent(Swal);
  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    organizationName: "",
    industry: "",
    role: "",
    budgetRange: "",
    subject: "",
    country: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .required("Name is required"),
    email: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .email("Invalid email address")
      .required("Email is required"),
    mobileNumber: yup
      .string()
      .required("Phone is required")
      .min(10, "Phone number must be at least 10 characters long")
      .max(10, "Phone number must be no longer than 15 characters"),
    subject: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .required("Subject is required")
      .min(5, "Message must be at least 5 characters long"),
    country: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .required("Country is required"),
    message: yup
      .string()
      .matches(/^\S/, "Name cannot start with a space")
      .required("Message is required")
      .min(25, "Message must be at least 25 characters long"),
  });

  const handleSubmit = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const resp = await addContactApi(value);
      console.log(resp);
      setSubmitting(false);
      resetForm();
      MySwal.fire({
        title: resp.data?.message || "Thank You! We will contact you soon!",
        icon: "success",
        showCloseButton: true,
        showConfirmButton: false,
        timer: 5000,
      });
    } catch (error) {
      const { response } = error;
      console.error(response);
      setSubmitting(false);
      toast.error(response.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <SEO
        title="Get In Touch - Reach Out to Vivcloud Technologies for Project Queries"
        description="Contact Vivcloud Technologies for all your project queries and inquiries. We're here to listen and eager to engage with you. Reach out to us today, and we'll respond as quickly as possible to assist you."
      />
      <section className="pt-20 pb-[200px] relative overflow-hidden bg-blue-dark">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div
          className="sm:w-[80%] mx-auto px-4 relative"
          data-aos="fade-up"
        >
          <h1 className="text-4xl md:text-5xl font-semibold text-white text-center">
            Get in Touch
          </h1>
          <ul className="flex max-sm:flex-col items-center justify-center mt-8">
            <li className="sm:pr-10">
              <Link
                href="mailto:vivcloudtech@gmail.com"
                className="flex items-center text-white md:text-lg"
              >
                {" "}
                <IoMdMail className="mr-2" /> vivcloudtech@gmail.com
              </Link>
            </li>
            <li className="max-sm:mt-3">
              <Link
                href="tel:(+91)8604042743"
                className="flex items-center text-white md:text-lg"
              >
                {" "}
                <BsFillTelephoneFill className="mr-2" />
                (+91) 8604 0427 43
              </Link>
            </li>
          </ul>
        </div>
      </section>
      {/* Contact Form */}
      <section
        className="pb-20 relative overflow-hidden mt-[-100px]"
        data-aos="fade-up"
      >
        <img
          src={ContactShadow}
          alt=""
          className="absolute w-full h-[250px] left-0 bottom-0"
        />
        <div className="drop-shadow-[0_69px_42px_rgba(200,200,200,0.25)] bg-white p-8 sm:p-20 w-[90%] mx-auto rounded-2xl md:flex">
          <div className="md:w-2/4 md:mt-24">
            <img
              src={contactFormImage}
              alt=""
            />
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="md:w-2/3">
                <div className="md:flex">
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pr-2">
                    <label className="block text-black-2">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    />
                    <ErrorMessage
                      name="name"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pl-2">
                    <label className="block text-black-2">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      name="email"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    />
                    <ErrorMessage
                      name="email"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pr-2">
                    <label className="block text-black-2">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <Field
                      name="mobileNumber"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      onPaste={validateOnPaste}
                      onKeyPress={validateOnNumber}
                      maxLength="10"
                    />
                    <ErrorMessage
                      name="mobileNumber"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pl-2">
                    <label className="block text-black-2">Company</label>
                    <Field
                      name="organizationName"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pr-2">
                    <label className="block text-black-2">Industry</label>
                    <Field
                      name="industry"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    />
                  </div>
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pl-2">
                    <label className="block text-black-2">Role</label>
                    <Field
                      name="role"
                      as="select"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    >
                      <option value="">Select a role</option>
                      {jobRoles.map(({ name, value }) => (
                        <option
                          key={value}
                          value={value}
                        >
                          {name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pr-2">
                    <label className="block text-black-2">Budget</label>
                    <Field
                      name="budgetRange"
                      as="select"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="50"
                    >
                      <option value="">Select a budget range</option>
                      {budgetRanges.map(({ name, value }) => (
                        <option
                          key={value}
                          value={value}
                        >
                          {name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="text-lg w-full md:w-1/2 mb-6 md:pl-2">
                    <label className="block text-black-2">
                      Country <span className="text-red-500">*</span>
                    </label>
                    <Field
                      name="country"
                      as="select"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                    >
                      <option value="">Select a country</option>
                      {countries.map(({ code, name }) => (
                        <option
                          key={code}
                          value={code}
                        >
                          {name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="country"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="text-lg w-full mb-6 md:pr-2">
                    <label className="block text-black-2">
                      Subject <span className="text-red-500">*</span>
                    </label>
                    <Field
                      name="subject"
                      type="text"
                      className="mt-2 border border-grey-9 w-full p-3 outline-none rounded focus:border-secondary"
                      maxLength="100"
                    />
                    <ErrorMessage
                      name="subject"
                      className="text-red-500 text-xs"
                      component="div"
                    />
                  </div>
                </div>
                <div className="text-lg">
                  <label className="block text-black-2">
                    Message <span className="text-red-500">*</span>
                  </label>
                  <Field
                    name="message"
                    as="textarea"
                    className="mt-2 border border-grey-9 w-full p-5 outline-none rounded focus:border-secondary"
                    rows={2}
                    maxLength="250"
                  />
                  <ErrorMessage
                    name="message"
                    className="text-red-500 text-xs"
                    component="div"
                  />{" "}
                </div>
                <button
                  className="flex items-center gap-1 bg-primary text-white mt-5 rounded-lg text-2xl py-2.5 px-10 hover:opacity-80"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Loading..." : "Submit"}
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    ></path>
                    <line
                      x1="10"
                      y1="14"
                      x2="21"
                      y2="3"
                    ></line>
                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                  </svg>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
      <section className="p-5">
        <div className="sm:w-[80%] mx-auto px-4">
          <iframe
            title="Vivcloud Technologies Pvt Ltd"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.575732233998!2d81.0169154771633!3d26.853443516559977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be394514ac873%3A0xe500e21398bd8da2!2sVivcloud%20Technologies%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1705078812362!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{
              border: 0,
              width: "100%",
              height: "500px",
              borderRadius: "5px",
            }}
          ></iframe>
        </div>
      </section>
      <EmployeeSpotlight />
      <Newsletter />
    </>
  );
};

export default Contact;
