import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createSubscribeApi, getBlogsApi } from "service";
import { dataURItoBlob } from "utils";
import BlogBackgroundImage from "assets/new-images/blog_bg.png";
import NewsletterBg from "assets/new-images/newsletter_bg.png";
import NewsletterImage from "assets/new-images/newsletter_img.png";
import Ellipse1 from "assets/new-images/ellipse_1.png";
import Ellipse2 from "assets/new-images/ellipse_2.png";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import { FaArrowRight } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.css";

const Blog = () => {
  const MySwal = withReactContent(Swal);
  const [blogs, setBlogs] = useState({
    loading: true,
    data: [],
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const loadBlogs = async () => {
    const param = {
      page: 0,
      limit: 20,
    };
    try {
      const resp = await getBlogsApi(param);
      setBlogs({ loading: false, data: resp.data || [] });
    } catch {
      setBlogs({ loading: false, data: [] });
    }
  };

  useEffect(() => {
    loadBlogs();
  }, []);

  const handleSubscribe = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const resp = await createSubscribeApi(value);
      console.log(resp);
      setSubmitting(false);
      resetForm();
      MySwal.fire({
        title:
          resp.data?.message ||
          "Welcome to the family! Thank you for subscribing. We promise to only send you the good stuff.",
        icon: "success",
        showCloseButton: true,
        showConfirmButton: false,
        timer: 5000,
      });
    } catch (error) {
      const { response } = error;
      console.error(response);
      setSubmitting(false);
      toast.error(response.data?.message || "Something went wrong");
    }
  };

  const loadMoreBlogs = () => {};

  const getBlogsContent = () => {
    if (blogs.loading) {
      return (
        <div className="flex flex-wrap">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="w-full md:w-1/2 lg:w-1/3 px-4 mt-7"
              data-aos="fade-up"
            >
              <div className="duration-500 ease-in-out group bg-grey-light shadow-lg rounded-md hover:shadow-2xl">
                <div className="relative overflow-hidden rounded-md bg-gray-300 h-60"></div>
                <div className="px-5 pb-5 rounded-md">
                  <div className="flex items-center text-[14px] mt-5">
                    <div className="w-[50px] h-[1px] bg-secondary mr-2"></div>
                    <div className="uppercase bg-gray-300 h-4 w-24"></div>{" "}
                  </div>
                  <ul className="flex mt-3 flex-wrap">
                    <li className="bg-gray-300 h-4 w-16 mr-2 rounded"></li>{" "}
                    <li className="bg-gray-300 h-4 w-16 rounded"></li>{" "}
                  </ul>
                  <div className="mt-3 h-6 bg-gray-300 rounded-md"></div>{" "}
                  <div className="md:text-lg text-gray-5 mt-2">
                    <div className="bg-gray-300 h-20 rounded-md"></div>{" "}
                  </div>
                  <div className="flex justify-end mt-4">
                    <div className="inline-flex items-center uppercase bg-gray-300 h-8 w-24 rounded-md"></div>{" "}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <InfiniteScroll
        dataLength={blogs.data.length}
        next={loadMoreBlogs}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center mt-4">
            <div className="loader"></div>
          </div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>You have seen it all!</b>
          </p>
        }
        className="flex flex-wrap"
      >
        {blogs.data.map((blog) => (
          <div
            key={blog.id}
            className="w-full md:w-1/2 lg:w-1/3 px-4 mt-7"
            data-aos="fade-up"
          >
            <div className="duration-500 h-[580px] ease-in-out group bg-grey-light shadow-lg rounded-md hover:shadow-2xl">
              <div className="relative overflow-hidden rounded-md">
                <img
                  src={dataURItoBlob(blog.thumbnailPhoto)}
                  alt=""
                  className="w-full h-full object-cover scale-105 group-hover:scale-125 duration-500 ease-in-out"
                />
                <div className="absolute bottom-0 left-0 bg-secondary text-white flex items-center justify-center flex-col w-[76px] h-[76px]">
                  <span className="text-[16px] mb-[-5px]">
                    {moment(blog.createdDate).format("MMM")}
                  </span>
                  <span className="text-[30px]">
                    {moment(blog.createdDate).format("DD")}
                  </span>
                </div>
              </div>
              <div className="px-5 pb-5 rounded-md">
                <div className="flex items-center text-[14px] text-gray-5 mt-5">
                  <span className="w-[50px] h-[1px] bg-secondary mr-2"></span>
                  <span className="uppercase">{blog.blogAuthor?.name}</span>
                </div>
                <ul className="flex mt-3 flex-wrap">
                  {blog.category?.split(",")?.map((tag, idx, array) => (
                    <li
                      className="pr-2"
                      key={idx}
                    >
                      {tag}
                      {idx < array.length - 1 && <span>,</span>}
                    </li>
                  ))}
                </ul>
                <h3 className="text-xl md:text-2xl font-semibold text-black-1 mt-3">
                  {blog.title}
                </h3>
                <p className="md:text-lg text-gray-5 mt-2 line-clamp-3">
                  {blog.description}
                </p>
                <div className="flex justify-end">
                  <Link
                    to={`/blog/${blog.id}`}
                    className="btn text-black-1 mt-4 hover:text-secondary inline-flex items-center uppercase"
                  >
                    Read More{" "}
                    <FaArrowRight className="btn-hover ml-2 duration-500 ease-in-out" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    );
  };

  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden min-h-[400px] flex items-center justify-center">
        <img
          src={BlogBackgroundImage}
          alt=""
          className="absolute inset-0 object-cover h-full w-full"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div
            className="text-center text-white"
            data-aos="fade-up"
          >
            <h1 className="text-5xl md:text-6xl font-semibold">
              Latest Blogs & Articles
            </h1>
            <p className="text-md md:text-lg pt-7 mx-20">
              Explore our insightful blogs for industry trends, expert insights,
              and valuable tips, providing a rich resource for staying informed
              and inspired in the ever-evolving digital landscape.
            </p>
          </div>
        </div>
      </section>
      {/* Blog Section */}
      <section className="py-14">
        <div className=" mx-auto px-4 relative">
          <div
            style={{
              minHeight: "400px",
              overflowY: "scroll",
            }}
          >
            {/* Blog Item */}
            {getBlogsContent()}
          </div>
        </div>
      </section>
      {/* Newsletter 2 */}
      <section className="py-20 relative max-lg:bg-blue-dark hidden">
        <img
          src={Ellipse2}
          alt=""
          className="absolute bottom-0 top-0 w-full z-10"
        />
        <img
          src={Ellipse1}
          alt=""
          className="absolute inset-0 h-full w-full z-10"
        />
        <div className="py-14 relative overflow-hidden">
          <img
            src={NewsletterBg}
            alt=""
            className="absolute inset-0 h-full w-full"
          />
          <div className="sm:w-[80%] mx-auto px-4 relative z-10">
            <div
              className="md:flex items-center max-lg:justify-center"
              data-aos="fade-up"
            >
              <div className="lg:w-1/2 md:pr-3">
                <h2 className="text-3xl md:text-4xl font-semibold text-white">
                  Start your 7-day free trial
                </h2>
                <p className="md:text-lg text-white mt-3">
                  Felix can help bring your dream website to life with ease.
                </p>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubscribe}
                >
                  {() => (
                    <Form className="relative max-w-[638px] mx-auto mt-[50px] rounded-full overflow-hidden">
                      <Field
                        name="email"
                        type="text"
                        className="w-full h-[60px] p-5 pr-[140px] md:pr-[180px] outline-0 bg-white"
                        maxLength="50"
                        placeholder="Enter your email"
                      />
                      <button className="bg-secondary text-white absolute right-0 h-[60px] w-[120px] md:w-[170px] rounded-full hover:bg-primary">
                        Subscribe
                      </button>
                    </Form>
                  )}
                </Formik>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="Image relative lg:absolute inset-0 z-10">
          <img
            src={NewsletterImage}
            alt=""
            className="mx-auto lg:absolute top-0 right-0 object-contain h-full"
          />
        </div>
      </section>
      <EmployeeSpotlight />
    </>
  );
};

export default Blog;
