import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { debounce } from "lodash";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import Newsletter from "components/Newsletter";
import { getJobsApi } from "service";
import InfiniteScroll from "react-infinite-scroll-component";

const JobListing = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
    tempdata: [],
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const loadJobs = async () => {
    const param = {
      page: 0,
      limit: 20,
    };
    try {
      const resp = await getJobsApi(param);
      const data = resp.data || [];
      setJobs({
        loading: false,
        data: data,
        tempdata: data,
      });
    } catch {
      setJobs({ loading: false, data: [] });
    }
  };

  useEffect(() => {
    loadJobs();
  }, []);

  const handleSearchJobs = debounce((value) => {
    setJobs((prev) => ({
      ...prev,
      data: prev.tempdata.filter((job) =>
        job.jobTitle.toLowerCase().includes(value.toLowerCase())
      ),
    }));
  }, 300);

  const onSearch = (e) => {
    handleSearchJobs(e.target.value);
  };

  const loadMoreBlogs = () => {};

  const getJobsContent = () => {
    if (jobs.loading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="text-primary border mb-5 border-black-3 border-dashed p-5 md:flex items-center rounded-md cursor-pointer hover:bg-gray-100"
        >
          <div className="flex-grow pr-3">
            <div>
              <div className="bg-gray-300 h-6 md:h-8 w-3/4 mb-2 rounded-md"></div>
              <div className="bg-gray-300 h-10 md:h-12 w-full rounded-md mt-4"></div>
            </div>
          </div>
          <div className="min-w-[200px] max-md:my-5">
            <div className="bg-gray-300 h-6 md:h-8 w-3/4 rounded-md mb-4"></div>
            <div className="flex items-center mt-3">
              <div className="bg-gray-300 h-6 w-2/5 rounded-full mr-2"></div>
              <div className="bg-gray-300 h-6 w-2/5 rounded-full"></div>
            </div>
          </div>
          <div className="max-sm:mt-5 inline-block">
            <div className="bg-gray-300 h-8 w-8 rounded-full"></div>
          </div>
        </div>
      ));
    }
    if (!jobs.data.length) {
      return (
        <div className="text-center mt-10">
          <h3 className="text-2xl">No Jobs Found...</h3>
        </div>
      );
    }
    return jobs.data.map((job) => (
      <div
        key={job.id}
        onClick={() => navigate(`/career/jobs/${job.id}`)}
        className="text-primary border mb-5 border-black-3 border-dashed p-5 md:flex items-center rounded-md cursor-pointer hover:bg-gray-100"
      >
        <div className="flex-grow pr-3">
          <div>
            <h4 className="text-xl md:text-2xl">{job.jobTitle}</h4>
            <p className="mt-4">{job.jobDescription}</p>
          </div>
        </div>
        <div className="min-w-[200px] max-md:my-5">
          <p className="text-xl">{job.jobLocation}</p>
          <div className="flex items-center mt-3">
            <span className="mr-2 text-sm rounded-full">{job.jobSkills}</span>
            <span className="mr-2 text-sm rounded-full">
              {job.jobExperience}
            </span>
          </div>
        </div>
        <Link
          to={`/career/jobs/${job.id}`}
          className="max-sm:mt-5 inline-block hover:text-secondary"
        >
          <FaAngleRight className="w-[32px] h-[32px]" />
        </Link>
      </div>
    ));
  };

  return (
    <>
      <section className="py-20 bg-blue-dark min-h-[400px] flex items-center justify-center">
        <div className="sm:w-[80%] mx-auto px-4 p-10 rounded-xl text-center text-white">
          <h1
            className="text-5xl md:text-6xl font-semibold"
            data-aos="fade-up"
          >
            Let's <span className="text-secondary">work</span> together!
          </h1>
          <p
            className="text-lg md:text-xl mt-6 max-w-[85%] mx-auto"
            data-aos="fade-up"
          >
            Take the first step towards a transformative future by partnering
            with us today. Explore our cutting-edge solutions in Blockchain,
            Data Engineering, and AI, and experience the power of innovation and
            seamless connectivity. Contact us now for a personalized
            consultation, and together, let's build a brighter tomorrow. Your
            success awaits!
          </p>
        </div>
      </section>
      <section className="relative py-14">
        <div className="sm:w-[80%] mx-auto px-4">
          <h2 className="font-semibold text-4xl md:text-5xl text-primary">
            We are hiring
          </h2>
          <div className="mt-10 xl:mt-5">
            <input
              type="text"
              className="border border-grey w-full md:text-base rounded-xl p-3 outline-none"
              placeholder="Search Job Title"
              onChange={onSearch}
            />
          </div>
          <div
            style={{
              height: "400px",
              overflowY: "scroll",
            }}
            className="p-5"
          >
            <InfiniteScroll
              dataLength={jobs.data.length}
              next={loadMoreBlogs}
              hasMore={hasMore}
              loader={
                <div className="flex justify-center mt-4">
                  <div className="loader"></div>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>You have seen it all!</b>
                </p>
              }
            >
              {getJobsContent()}
            </InfiniteScroll>
          </div>
        </div>
      </section>
      <EmployeeSpotlight />
      <Newsletter />
    </>
  );
};

export default JobListing;
