import serviceBg from "assets/new-images/services_bg.png";
import BannerImage from "assets/new-images/application_developer.png";
import SuccessStoryImage1 from "assets/new-images/success_story1.png";
import SuccessStoryImage2 from "assets/new-images/success_story2.png";
import Newsletter from "components/Newsletter";

const ApplicationDevelopement = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <h1
                className="text-4xl md:text-6xl font-semibold text-white text-center"
                data-aos="fade-up"
              >
                Application Developement
              </h1>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={BannerImage}
                alt=""
                className="mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Service Details */}
      <section className="py-20">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Our Expertise
            </h2>
            <p data-aos="fade-up">
              In a world where digital convenience is not just appreciated but
              expected, application development is the gateway to innovation and
              customer engagement. We specialize in turning complex problems
              into simple, elegant application solutions that drive business
              value and user satisfaction.
            </p>
            <ul className="list-disc ml-10">
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Mobile Application Development – Anytime, Anywhere:
                </strong>
                We bring your services to the fingertips of your users with
                mobile applications that combine functionality with
                user-friendly design. Our mobile app developers are experts in
                creating experiences that are consistent across all devices,
                ensuring that your users have access to your business anytime,
                anywhere.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Desktop Application Development – Power and Performance:
                </strong>
                For software that requires full power and rich functionality,
                our desktop application development services deliver. We focus
                on creating fast, reliable, and engaging applications that users
                can access directly from their desktops, regardless of the
                platform they are using.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Web Application Development – Versatility Meets User
                  Experience:
                </strong>
                Our web applications are built to offer the perfect balance
                between versatility and user experience. With a focus on
                responsive design and cross-platform compatibility, we ensure
                that your web app provides a seamless experience, whether
                accessed via a browser on a desktop, tablet, or smartphone.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Cross-Platform Development for Broader Reach:
                </strong>
                Embrace the power of cross-platform applications to maximize
                your reach. Our development strategies involve frameworks like
                React Native and Flutter to ensure that your app provides a
                native-like experience on both iOS and Android platforms without
                the need to develop separately for each one.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Let's Create Exceptional Applications Together:
                </strong>
                Join us on a journey to digital excellence. Whether you're
                looking to innovate within your industry or provide practical
                solutions to your customers, our application development team is
                ready to bring your vision to life.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Success Story */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Success Story
            </h2>
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage1}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How AI breakthrough to the Future?
                  </h3>
                </div>
              </div>
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage2}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How Technology emerging in 2023?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default ApplicationDevelopement;
