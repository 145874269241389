import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createSubscribeApi } from "service";
import MeetPeopleVector from "assets/new-images/meet_people_vector.png";

const Newsletter = () => {
  const MySwal = withReactContent(Swal);

  const initialValues = {
    emailAddress: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubscribe = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const resp = await createSubscribeApi(value);
      setSubmitting(false);
      resetForm();
      MySwal.fire({
        title:
          resp.data?.message ||
          "Welcome to the family! Thank you for subscribing. We promise to only send you the good stuff.",
        icon: "success",
        showCloseButton: true,
        showConfirmButton: false,
        timer: 5000,
      });
    } catch (error) {
      const { response } = error;
      console.error(response);
      setSubmitting(false);
      toast.error(response.data?.message || "Something went wrong");
    }
  };

  return (
    <section className="relative">
      <img
        src={MeetPeopleVector}
        alt={""}
        className="absolute top-[-40px] right-[10%]"
      />
      <div className="py-20 bg-blue-dark relative">
        <div className="sm:w-[80%] mx-auto px-4">
          <div
            className="text-center"
            data-aos="fade-up"
          >
            <h5 className="text-2xl md:text-3xl text-white mb-4">
              I need more info!
            </h5>
            <div className="w-[200px] h-[4px] rounded-full bg-secondary mx-auto"></div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubscribe}
            >
              {() => (
                <Form className="relative max-w-[500px] mx-auto mt-[50px] rounded-full overflow-hidden">
                  <Field
                    name="emailAddress"
                    type="text"
                    className="w-full h-[60px] p-5 pr-[140px] md:pr-[180px] outline-0 bg-white"
                    maxLength="50"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-primary to-secondary hover:bg-gradient-to-l text-white absolute right-0 h-[60px] w-[120px] md:w-[170px] rounded-full hover:bg-primary"
                  >
                    Subscribe
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
