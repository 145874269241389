import serviceBg from "assets/new-images/services_bg.png";
import BannerImage from "assets/new-images/ui-ux.png";
import SuccessStoryImage1 from "assets/new-images/success_story1.png";
import SuccessStoryImage2 from "assets/new-images/success_story2.png";
import Newsletter from "components/Newsletter";

const UiUxDesign = () => {
  return (
    <>
      {/* Banner */}
      <section className="py-14 relative overflow-hidden">
        <img
          src={serviceBg}
          alt=""
          className="absolute inset-0 object-cover h-full w-full object-left"
        />
        <div className="sm:w-[80%] mx-auto px-4 relative">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <h1
                className="text-4xl md:text-6xl font-semibold text-white text-center"
                data-aos="fade-up"
              >
                Ui/Ux Design
              </h1>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={BannerImage}
                alt=""
                className="mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Service Details */}
      <section className="py-20">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Our Expertise
            </h2>
            <p data-aos="fade-up">
              Craft user-friendly site: intuitive navigation, responsive design,
              visually appealing. Prioritize accessibility, cohesive colors,
              thoughtful typography for enhanced user satisfaction and
              engagement across devices.
            </p>

            <ul className="list-disc ml-10">
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Designing with the User in Mind::
                </strong>
                Our UI/UX design process is anchored in empathy and innovation.
                We delve into the user's world, harnessing insights to tailor
                experiences that resonate on a personal level. Our designers are
                artists and architects of the digital landscape, sculpting
                interfaces that are not only visually striking but also
                intuitively navigable.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Visual Storytelling Meets Interactive Design:
                </strong>
                We believe that every pixel and every interaction tells a story.
                Our design philosophy marries aesthetic elegance with
                interactive design to narrate your brand's story through every
                click, swipe, and tap. The result? A digital narrative that
                captivates and engages your audience from the first glance to
                the last action.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  A Collaborative Symphony of Creativity:
                </strong>
                UI/UX design at our studio is a collaborative symphony, where
                each designer's creativity contributes to a grander vision. We
                are not just designing interfaces; we're crafting the
                environments where your users live, work, and play. With a
                relentless focus on design excellence, our team ensures that
                every element is purposeful, every transition is smooth, and
                every interaction is memorable.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Responsive, Adaptive, and Inclusive:
                </strong>
                In today’s multi-device world, we champion designs that adapt
                and respond to an array of platforms, ensuring a consistent and
                inclusive user experience. From the vast landscape of desktops
                to the intimate screens of smartphones, our designs maintain
                their integrity and impact, regardless of where they're viewed.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Elevating Brands, One Experience at a Time:
                </strong>
                Our mission is to elevate brands by designing experiences that
                are not just used but loved. By fusing the principles of UI/UX
                with our creative prowess, we craft experiences that are both
                beautiful and beneficial, turning users into advocates and
                interfaces into gateways of growth.
              </li>
              <li
                className="mt-6"
                data-aos="fade-up"
              >
                <strong className="pr-2">
                  Let's Build the Future of Digital Experiences Together:
                </strong>
                Join us in the exciting journey of shaping the future of digital
                experiences. Whether you’re a startup or an established
                enterprise, our UI/UX design team is ready to collaborate and
                create digital magic that propels your brand to new heights.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Success Story */}
      <section className="py-14 hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="text-lg md:text-xl text-grey-6">
            <h2
              className="text-4xl md:text-5xl font-semibold mb-10 xl:mb-16 text-primary"
              data-aos="fade-up"
            >
              Success Story
            </h2>
            <div className="flex flex-wrap">
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage1}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How AI breakthrough to the Future?
                  </h3>
                </div>
              </div>
              <div
                className="w-full md:w-1/2 xl:w-2/5 px-3 my-3 group"
                data-aos="fade-up"
              >
                <div className="relative h-[370px] flex items-end p-6 rounded-2xl overflow-hidden">
                  <img
                    src={SuccessStoryImage2}
                    alt=""
                    className="absolute inset-0 object-cover h-full w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                  <h3 className="text-white relative ">
                    How Technology emerging in 2023?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* NewsLetter */}
      <Newsletter />
    </>
  );
};

export default UiUxDesign;
