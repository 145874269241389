import mobileApp from "assets/new-images/Mobile_app.png";
import aboutImage from "assets/new-images/about-us-video.png";
import HomeBanner1 from "assets/new-images/home_banner1.webp";
import HomeBanner2 from "assets/new-images/home_banner2.webp";
import HomeBanner3 from "assets/new-images/home_banner3.webp";
import MeetPeopleLogo1 from "assets/new-images/meet_people_logo1.png";
import MeetPeopleLogo2 from "assets/new-images/meet_people_logo2.png";
import MeetPeopleLogo3 from "assets/new-images/meet_people_logo3.png";
import softwareTesting from "assets/new-images/software_testing.png";
import webDevelopment from "assets/new-images/web-development.png";
import joinOurTeamImage from "assets/new-images/web-footer-spark.svg";
import EmployeeSpotlight from "components/EmployeeSpotlight";
import Newsletter from "components/Newsletter";
import SEO from "components/SEO";
import { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { RiSingleQuotesL, RiSingleQuotesR } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { getBlogsApi } from "service";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { dataURItoBlob } from "utils";
import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Home = () => {
  const navigate = useNavigate();
  const swiperRef = useRef();

  const whatWeOfferPagination = {
    clickable: true,
    bulletActiveClass: "!bg-blue-light !opacity-100",
    renderBullet: function (index, className) {
      return `<span class="!w-[15px] !h-[15px] rounded-full ${className}"></span>`;
    },
  };

  const bannerPagination = {
    clickable: true,
    bulletActiveClass: "!bg-white",
    renderBullet: function (index, className) {
      return `<span class="!w-[15px] !h-[15px] rounded-full bg-grey-light !opacity-100 ${className}"></span>`;
    },
  };

  const HomeBanner = [
    {
      title: (
        <div className="tracking-wide">
          <p>
            Innovate with{" "}
            <span className="text-secondary text-outline-white inline-block">
              Viv Cloud
            </span>
          </p>
          <p className="mt-2">Technology Solutions</p>
        </div>
      ),
      subtitle: "Crush your social goals.Again and again",
      image: HomeBanner1,
    },
    {
      title: (
        <div className="tracking-wide">
          <p>
            Empower Your Business with{" "}
            <span className="text-secondary text-outline-white inline-block">
              Smart
            </span>
          </p>
          <p className="mt-2">IT Infrastructure</p>
        </div>
      ),
      subtitle: "Building robust systems for an interconnected world",
      image: HomeBanner2,
    },
    {
      title: (
        <div className="tracking-wide">
          <p>
            Secure Your{" "}
            <span className="text-secondary text-outline-white inline-block">
              Future
            </span>
          </p>
          <p className="mt-2">Embrace resilience</p>
        </div>
      ),
      subtitle: "Advanced cybersecurity to protect and propel your enterprise",
      image: HomeBanner3,
    },
  ];

  const Testimonials = [
    {
      name: "",
      description:
        "Vivcloud revolutionized our online presence with outstanding web design and digital marketing services. Their team exceeded our expectations, significantly boosting our sales and engagement through a personalized approach. Choosing Vivcloud fueled our business growth.",
    },
    {
      name: "",
      description:
        "Collaborating with Vivcloud was transformative for our business. Their SEO and content marketing expertise significantly increased our traffic and leads. The team's unmatched professionalism and passion ensured our project's success, making Vivcloud an essential partner for digital strategy enhancement.",
    },
    {
      name: "",
      description:
        "Vivcloud's holistic digital marketing approach was impressive from the start. They not only redesigned our website but also crafted a comprehensive strategy that improved our brand visibility and engagement. Their dedication to understanding our market and audience establishes Vivcloud as a trusted partner in our journey to success.",
    },
  ];
  const [blogs, setBlogs] = useState({
    loading: true,
    data: [],
  });

  const loadBlogs = async () => {
    const param = {
      page: 0,
      limit: 20,
    };
    try {
      const resp = await getBlogsApi(param);
      setBlogs({ loading: false, data: resp.data || [] });
    } catch {
      setBlogs({ loading: false, data: [] });
    }
  };

  useEffect(() => {
    loadBlogs();
  }, []);

  const getBlogsContent = () => {
    if (blogs.loading) {
      return Array.from({ length: 4 }).map((_, index) => (
        <SwiperSlide key={index}>
          <div className="blog-item rounded-md shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)]">
            <div
              className="blog_image overflow-hidden rounded-2xl bg-gray-300"
              style={{ height: "175px" }}
            ></div>
            <div className="px-2 mt-4">
              <div className="h-4 bg-gray-300 rounded-md"></div>
            </div>
            <div className="px-2 mt-4">
              <div className="h-14 bg-gray-300 rounded-md"></div>
            </div>
            <div className="flex justify-end px-2 pb-4 mt-4">
              <div className="w-20 h-5 bg-gray-300 rounded-md"></div>
            </div>
          </div>
        </SwiperSlide>
      ));
    }
    return blogs.data.map((blog) => (
      <SwiperSlide key={blog.id}>
        <div className="blog-item group h-[450px] rounded-md shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] hover:shadow-[0_0px_40px_3px_rgba(0,0,0,0.05)] duration-500 ease-in-out">
          <div className="blog_image overflow-hidden rounded-2xl">
            <img
              src={dataURItoBlob(blog.thumbnailPhoto)}
              alt={blog.title}
              height={175}
              className="w-full group-hover:scale-125 duration-500 ease-in-out"
            />
          </div>
          <h3 className="font-semibold text-lg px-4 mt-4">{blog.title}</h3>
          <p className="text-sm text-grey-8 px-4 mt-1">{blog.description}</p>
          <div className="flex justify-end px-5 pb-5">
            <button
              onClick={() => navigate(`/blog/${blog.id}`)}
              className="flex items-center hover:text-secondary text-primary mt-5"
            >
              Read More <FaArrowRightLong className="ml-2" />
            </button>
          </div>
        </div>
      </SwiperSlide>
    ));
  };

  return (
    <>
      <SEO
        title="Vivcloud Technologies - Innovative IT Solutions for Business Growth"
        description="Partner with Vivcloud Technologies for innovative IT solutions that drive business growth. From UI/UX design to web development and cloud services, we're your technology partner."
      />
      {/* Banner */}
      <section>
        <Swiper
          slidesPerView={1}
          pagination={bannerPagination}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
        >
          {HomeBanner.map((banner, index) => (
            <SwiperSlide
              key={index}
              className="!h-auto"
            >
              <div className="py-[75px] relative h-full">
                <img
                  src={banner.image}
                  alt={""}
                  className="absolute w-full h-full object-cover overflow-hidden inset-0"
                />
                <div className="absolute bg-blue-dark inset-0 opacity-50"></div>
                <div
                  className="relative z-10 sm:w-[80%] mx-auto px-4"
                  data-aos="fade-right"
                >
                  <h1 className="text-4xl sm:text-5xl md:text-6xl text-white text-semibold leading-[40px] sm:leading-normal">
                    {banner.title}
                  </h1>
                  <p className="text-white text-semibold text-xl md:text-2xl mt-4">
                    {banner.subtitle}
                  </p>
                  <button
                    onClick={() => navigate("/about")}
                    className="mt-6 text-lg md:text-xl text-white text-bold flex items-center"
                  >
                    Learn More{" "}
                    <span className="ml-2 w-[40px] h-[40px] rounded-full bg-gradient-to-r from-button-gradiant-1 to-button-gradiant-2 flex items-center justify-center">
                      <FaArrowRight />
                    </span>
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      {/* What we offer */}
      <section className="py-10 bg-grey-2 max-md:px-4">
        <div className="flex justify-center flex-col items-center md-5">
          <h2 className="text-2xl md:text-3xl text-primary">
            What we Offer
          </h2>
        </div>
        <div className="blog-cards px-4 sm:px-10">
          <Swiper
            slidesPerView={4}
            spaceBetween={25}
            pagination={whatWeOfferPagination}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Pagination, Autoplay]}
            className="what_we_offer !pb-4"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={mobileApp}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  UI/UX Design
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Craft user-friendly site: intuitive navigation, responsive
                  design, visually appealing. Prioritize accessibility, cohesive
                  colors, thoughtful typography for enhanced user satisfaction
                  and engagement across devices.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={softwareTesting}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  Web Development
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Elevate your online presence with expert web development. We
                  create responsive, high-performance websites with cutting-edge
                  features, ensuring a seamless user experience. Trust us to
                  bring your digital vision to life.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={webDevelopment}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  Ecommerce Development
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Transform your online store with expert ecommerce development.
                  Craft user-friendly, responsive platforms with advanced
                  features. Prioritize seamless navigation and performance for a
                  superior shopping experience.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={mobileApp}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  Web Application
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Revolutionize your business processes with our bespoke web
                  applications. We design and develop scalable, user-friendly
                  solutions tailored to your needs, ensuring optimal performance
                  and a seamless user experience.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={webDevelopment}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  Mobile App Development
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Transform your ideas into powerful mobile apps with our expert
                  development services. We craft intuitive, high-performance
                  applications tailored to your needs, ensuring a seamless user
                  experience on iOS and Android platforms.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={softwareTesting}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  Cloud Based Services
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Unlock the potential of Cloud Based Services for seamless
                  scalability, security, and efficiency. Our solutions offer
                  advanced features, ensuring a reliable and flexible
                  infrastructure to propel your business into the future.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-grey-light shadow-[0_4px_30px_0px_rgba(0,0,0,0.05)] p-4 rounded-lg my-10">
                <div className="bg-grey-light w-[58px] h-[58px] border border-secondary rounded-full flex justify-center items-center">
                  <img
                    src={softwareTesting}
                    alt=""
                  />
                </div>
                <h4 className="text-grey-8 md:text-lg mt-5">
                  24/7 Support
                </h4>
                <p className="mt-2 text-grey-6 text-sm md:text-sm">
                  Experience uninterrupted peace of mind with our 24/7 support.
                  Our dedicated team is here around the clock to assist you with
                  any queries or concerns. Your satisfaction is our priority,
                  ensuring seamless support whenever you need it.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* About Us */}
      <section className="py-10 overflow-hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="md:flex ">
            <div className="md:w-1/2 md:pr-3">
              <div
                className="w-[69px] mb-5 h-[5px] bg-blue-light"
                data-aos="fade-right"
              ></div>
              <h2
                className="text-2xl md:text-3xl mb-6 text-primary"
                data-aos="fade-right"
              >
                About Us
              </h2>
              <p
                className="text-lg md:text-xl mb-3 text-grey-6"
                data-aos="fade-up"
              >
                We{" "}
                <span className="text-secondary">add development capacity</span>{" "}
                to tech teams. Our value isn’t limited to building teams but is
                equally distributed across the project lifecycle. We are a
                custom software development company that guarantees the
                successful delivery of your project.
              </p>
              <Link
                to="/about"
                className="flex items-center text-blue-dark hover:text-secondary"
                data-aos="fade-up"
              >
                See more Informations <FaArrowRightLong className="ml-2" />
              </Link>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={aboutImage}
                alt=""
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Meet the people we are working with */}
      <section className="py-10">
        <div className="sm:w-[80%] mx-auto px-4">
          <div
            className="flex flex-col mb-10"
            data-aos="fade-right"
          >
            <div className="w-[69px] mb-5 h-[5px] bg-blue-light"></div>
            <h2 className="text-2xl md:text-3xl text-primary">
              Meet the People <br />{" "}
              <span className="font-bold">We are Working With</span>
            </h2>
          </div>
        </div>
        <div className="relative">
          <div
            className="bg-gray-4 py-[40px] relative px-4 sm:px-10"
            data-aos="fade-up"
          >
            <Swiper
              slidesPerView={6}
              spaceBetween={30}
              loop={true}
              navigation
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              className="!px-3 sm:!px-5 md:!px-10"
              // centeredSlides={true}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo1}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo2}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo3}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo1}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo2}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo3}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo1}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo2}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-center">
                  <img
                    src={MeetPeopleLogo3}
                    alt=""
                    className="h-[80px] object-contain w-[80%]"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      {/* Why customers love */}
      <section className="py-10">
        <div className="sm:w-[80%] mx-auto px-4">
          <div
            className="flex justify-center flex-col items-center mb-10"
            data-aos="fade-up"
          >
            <div className="w-[69px] mb-5 h-[5px] bg-blue-light"></div>
            <h2 className="text-2xl md:text-3xl text-primary text-center">
              Working with us
            </h2>
          </div>
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            centeredSlides={true}
            data-aos="fade-up"
          >
            {Testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="relative md:w-[70%] lg:w-[50%] mx-auto">
                  <RiSingleQuotesL className="absolute top-0 left-0 text-blue-light w-[40px] h-[40px]" />
                  <p className=" md:text-xl text-grey-6 text-center px-[40px] sm:px-[50px]">
                    {testimonial.description}
                  </p>
                  <RiSingleQuotesR className="absolute bottom-0 right-0 text-blue-light w-[40px] h-[40px]" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div
            className="flex justify-center flex-wrap mt-10 md:mt-20"
            data-aos="fade-up"
          >
            {teams.data.map((team, index) => (
              <div
                key={index}
                className="group card_item flex justify-center flex-col items-center md:mt-0 mt-5 px-5"
              >
                <div className="w-[100px] h-[100px] rounded-full overflow-hidden">
                  <img
                    src={dataURItoBlob(team.photo)}
                    alt=""
                    className="w-full group-hover:scale-125 duration-500 ease-in-out"
                  />
                </div>
                <div className="flex items-center mt-4">
                  {[...Array(team.rating)].map((_, index) => (
                    <FaStar
                      key={index}
                      className="text-secondary mx-1"
                    />
                  ))}
                </div>
                <h6 className="mt-4 md:text-lg font-bold">{team.name}</h6>
                <p className="mt-1 text-sm">{team.designation}</p>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      {/* Blogs */}
      <section className="py-10 max-md:px-4">
        <div
          className="flex justify-center flex-col items-center mb-10"
          data-aos="fade-up"
        >
          <div className="w-[69px] mb-5 h-[5px] bg-blue-light"></div>
          <h2 className="text-2xl md:text-3xl text-primary">
            Blogs
          </h2>
        </div>
        <div
          className="blog-cards px-4 sm:px-10"
          data-aos="fade-up"
        >
          <Swiper
            slidesPerView={4}
            spaceBetween={25}
            loop={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="mySwiper !py-5"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {getBlogsContent()}
          </Swiper>
          <div className="text-[30px] flex items-center justify-end">
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              className="mr-3"
            >
              <FaArrowLeftLong />
            </button>
            <button
              onClick={() => swiperRef.current?.slideNext()}
              className="ml-3"
            >
              <FaArrowRightLong />
            </button>
          </div>
        </div>
      </section>
      {/* Join our Team */}
      <section className="py-14 bg-blue-light mb-[-40px] overflow-hidden">
        <div className="sm:w-[80%] mx-auto px-4">
          <div className="md:flex items-center">
            <div className="md:w-1/2 md:pr-3">
              <div
                className="w-[69px] mb-5 h-[5px] bg-blue-dark"
                data-aos="fade-right"
              ></div>
              <h2
                className="text-2xl md:text-3xl mb-6 text-white"
                data-aos="fade-right"
              >
                Join our team
              </h2>
              <p
                className="text-lg md:text-xl mb-3 text-white"
                data-aos="fade-up"
              >
                Work with the most innovative colleagues, the hottest
                technologies, and truly inspired clients!
              </p>
              <Link
                to="/career"
                className="hidden lg:inline-block bg-primary hover:bg-secondary duration-500 ease-in-out text-white rounded-full text-md py-2 px-3 mt-5"
                data-aos="fade-up"
              >
                View talent opportunities
              </Link>
            </div>
            <div className="md:w-1/2 pt-7 md:pt-0 md:pl-3">
              <img
                src={joinOurTeamImage}
                alt=""
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
      <EmployeeSpotlight />
      <Newsletter />
    </>
  );
};

export default Home;
